<template>
  <div>
    <div class="inner-banner">
    <div class="container">
      <div class="inner-title text-center">
        <h3>About Us</h3>
        <ul>
          <li>
            <router-link to="/">Home</router-link>
          </li>
          <li>
            <i class="bx bx-chevrons-right"></i>
          </li>
          <li>About Us</li>
        </ul>
      </div>
    </div>
    <div class="inner-shape">
      <img src="/assets/images/shape/inner-shape.png" alt="Images" />
    </div>
  </div>

  <div class="about-area ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="about-play">
            <img src="/assets/images/about/about-img1.jpg" alt="About Images" />
            <div class="about-play-content">
              <span>Watch Our Intro Video</span>
              <h2>Perfect Solution for It Services!</h2>
              <div class="play-on-area">
                <a
                  href="https://www.youtube.com/watch?v=tUP5S4YdEJo"
                  class="play-on popup-btn"
                  ><i class="bx bx-play"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="about-content ml-25">
            <div class="section-title">
              <span class="sp-color2">2 Years of Experience</span>
              <h2>Values & Missions</h2>
              <div style="text-align:justify">
                <p>
                  Africa is one of the continents with so many problems, which are but not limited to security, 
                  agriculture, transportation, education, energy. 
                </p>
                <p>
                  Our aim is to help governments, organizations and small business turning these naging problems into opportunities. 
                  <b>We firmily believe that everything is possible.</b> 
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <ul class="about-list text-start">
                  <li>
                    <i class="bx bxs-check-circle"></i>Team of Experts
                  </li>
                  <li>
                    <i class="bx bxs-check-circle"></i>Experienced in various IT Services
                  </li>
                  <li>
                    <i class="bx bxs-check-circle"></i>Time and budget prioritization
                  </li>
                </ul>
              </div>
              <div class="col-lg-6 col-md-6">
                <ul class="about-list about-list-2 text-start">
                  <li>
                    <i class="bx bxs-check-circle"></i>Understanding of client's needs
                  </li>
                  <li>
                    <i class="bx bxs-check-circle"></i>Implementing efficient and reliable solutions
                  </li>
                  <li>
                    <i class="bx bxs-check-circle"></i>Very good quality services
                  </li>
                </ul>
              </div>
            </div>
            <p class="about-content-text">
              Have you been struggling with finding the right IT Services organization that will respond quickly to your business' needs?
              Don't look further, DARA TECH is the perfect solution.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="choose-area pt-100 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="choose-content mr-20">
            <div class="section-title">
              <span class="sp-color1">Why Choose Us</span>
              <h2>We Provide Truly Prominent IT Solutions.</h2>
              <p>
                Because we want your business to standout in the thoughest competition, so 
                we take the responsibility to provide you with prominent and sophisticated solutions.
              </p>
            </div>
            <div class="row">
              <div class="col-lg-6 col-6">
                <div class="choose-content-card">
                  <div class="content">
                    <i class="flaticon-practice"></i>
                    <h3>Experience</h3>
                  </div>
                  <p>
                      That's the professional style of our team of experts
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-6">
                <div class="choose-content-card">
                  <div class="content">
                    <i class="flaticon-help"></i>
                    <h3>Quick Support</h3>
                  </div>
                  <p>
                    Our support team is available 24/7 to assist you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="choose-img">
            <img src="/assets/images/choose-img.jpg" alt="Images" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="security-area pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color2">IT Security & Computing</span>
        <h2>
          Searching for a Solution! We Provide Truly Prominent IT Solutions
        </h2>
      </div>
      <div class="row pt-45">
        <div class="col-lg-4 col-sm-6">
          <div class="security-card">
            <i class="flaticon-cyber-security"></i>
            <h3><router-link to="/services/system-security">System Security</router-link></h3>
            <p>
              We help you securing your IT systems against malicious users. Penetration and 
              infiltration testing are our basics.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="security-card">
            <i class="flaticon-web-development"></i>
            <h3><router-link to="/services/app-development">App Development</router-link></h3>
            <p>
              Either desktop, web or mobile application, we can transform any innovative idea 
              into a reliable software system.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="security-card">
            <i class="flaticon-effective"></i>
            <h3><router-link to="/services/data-analysis">Data Analysis</router-link></h3>
            <p>
              To improve the product or service accpetance by customers, you need to understand the customers

            </p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="security-card">
            <i class="flaticon-consultant"></i>
            <h3><router-link to="/services/payment-systems">Payment Systems</router-link></h3>
            <p>
              The world is going cashless. So, we provide to businesses and financial institutions
              the systems to send and receive payments.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="security-card">
            <i class="flaticon-consulting"></i>
            <h3><router-link to="/services/cloud-computing">Cloud Computing</router-link></h3>
            <p>
              We help you saving maintenance cost and preventing your IT infrastructure from 
                natural disasters.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="security-card">
            <i class="flaticon-consultant"></i>
            <h3><router-link to="/services/it-consulting">IT Consulting</router-link></h3>
            <p>
              We direct your choice and help you implementing the appropriate IT infrastructure 
              needed for your business
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="brand-area-two ptb-100">
    <div class="container">
      <div class="brand-slider owl-carousel owl-theme">
        <div class="brand-item">
          <img src="/assets/images/brand-logo/brand-style1.png" alt="Images" />
        </div>
        <div class="brand-item">
          <img src="/assets/images/brand-logo/brand-style2.png" alt="Images" />
        </div>
        <div class="brand-item">
          <img src="/assets/images/brand-logo/brand-style3.png" alt="Images" />
        </div>
        <div class="brand-item">
          <img src="/assets/images/brand-logo/brand-style4.png" alt="Images" />
        </div>
        <div class="brand-item">
          <img src="/assets/images/brand-logo/brand-style5.png" alt="Images" />
        </div>
        <div class="brand-item">
          <img src="/assets/images/brand-logo/brand-style3.png" alt="Images" />
        </div>
      </div>
    </div>
  </div>

  <div class="counter-area pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color2">Numbers Are Talking</span>
        <h2>Let’s Check Our Business Growth and Success Story</h2>
        <p>
          We are not yet satsified with our achievement. We are ready to do more.
        </p>
      </div>
      <div class="row pt-45">
        <div class="col-lg-3 col-6 col-md-3">
          <div class="counter-another-content">
            <i class="flaticon-web-development"></i>
            <h3>4205+</h3>
            <span>Lines of Code</span>
          </div>
        </div>
        <div class="col-lg-3 col-6 col-md-3">
          <div class="counter-another-content">
            <i class="flaticon-consulting-1"></i>
            <h3>5+</h3>
            <span>IT Consulting</span>
          </div>
        </div>
        <div class="col-lg-3 col-6 col-md-3">
          <div class="counter-another-content">
            <i class="flaticon-startup"></i>
            <h3>2+</h3>
            <span>Fully Launched</span>
          </div>
        </div>
        <div class="col-lg-3 col-6 col-md-3">
          <div class="counter-another-content">
            <i class="flaticon-tick"></i>
            <h3>2+</h3>
            <span>Project Completed</span>
          </div>
        </div>
      </div>
    </div>
    <div class="counter-shape">
      <div class="shape1">
        <img src="/assets/images/shape/shape1.png" alt="Images" />
      </div>
      <div class="shape2">
        <img src="/assets/images/shape/shape2.png" alt="Images" />
      </div>
    </div>
  </div>
  <div class="inner-banner">
      <div class="container">
        <div class="inner-title text-center">
          <h3>Team</h3>
          <ul>
            <li>
              <router-link to="/">Home</router-link>
            </li>
            <li>
              <i class="bx bx-chevrons-right"></i>
            </li>
            <li>Team</li>
          </ul>
        </div>
      </div>
      <div class="inner-shape">
        <img src="/assets/images/shape/inner-shape.png" alt="Images" />
      </div>
    </div>

    <div class="security-area pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color2">Our Team</span>
        <h2>
          Our family of wonderful people behind everything we do
        </h2>
      </div>
        <div class="row pt-45">
          <div class="col-lg-4 col-md-6">
            <div class="team-card">
              <img src="/assets/images/team/team-img1.jpg" alt="Team Images" />
              <ul class="social-link">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="bx bxl-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/?lang=en" target="_blank">
                    <i class="bx bxl-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="bx bxl-linkedin-square"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/" target="_blank">
                    <i class="bx bxl-instagram"></i>
                  </a>
                </li>
              </ul>
              <div class="content">
                <h3>Ulrich Fangnikoue</h3>
                <span>Executive Director</span>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="team-card">
              <img src="/assets/images/team/team-img2.jpg" alt="Team Images" />
              <ul class="social-link">
                <li>
                  <a href="https://twitter.com/tomakponvi" target="_blank">
                    <i class="bx bxl-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/in/tom-avoulete-kponvi-7a4270190/" target="_blank">
                    <i class="bx bxl-linkedin-square"></i>
                  </a>
                </li>
              </ul>
              <div class="content">
                <h3>Tom A. Kponvi</h3>
                <span>Lead Engineer</span>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="team-card">
              <img src="/assets/images/team/team-img3.jpg" alt="Team Images" />
              <ul class="social-link">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="bx bxl-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/?lang=en" target="_blank">
                    <i class="bx bxl-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="bx bxl-linkedin-square"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/" target="_blank">
                    <i class="bx bxl-instagram"></i>
                  </a>
                </li>
              </ul>
              <div class="content">
                <h3>Freeman Dossou</h3>
                <span>UI/UX Designer</span>
              </div>
            </div>
          </div>
         
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'About'
}
</script>
