<template>
     <div>
       <div class="inner-banner">
      <div class="container">
        <div class="inner-title text-center">
          <h3>Payment Systems</h3>
          <ul>
            <li>
              <router-link to="/services">Services</router-link>
            </li>
            <li>
              <i class="bx bx-chevrons-right"></i>
            </li>
            <li>Payment Systems</li>
          </ul>
        </div>
      </div>
      <div class="inner-shape">
        <img src="/assets/images/shape/inner-shape.png" alt="Images" />
      </div>
    </div>

    <div class="service-details-area pt-100 pb-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="service-article">
              <div class="service-article-img">
                <img
                  src="/assets/images/services/service-details.jpg"
                  alt="Images"
                />
              </div>
              <div class="service-article-content">
                <h2>Payment Systems</h2>
                <p style="text-align:justify">
                  It’s safe to say that cashless transactions have revolutionized 
                  the financial outlook of India. Utilizing mobile phones to make 
                  payments instead of opting for the traditional modes of payment has 
                  increased tremendously since demonetisation. When the business operations 
                  of most SMEs came to a standstill during demonetisation, the businesses 
                  that had shifted to cashless transactions managed to curb losses.
                </p>
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <ul class="service-article-list service-article-rs">
                      <li>
                        <i class="bx bxs-check-circle"></i>Easy and simple cashless transactions
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Fast and reliable payment systems
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Remittance into mobile wallet and bank account
                      </li>
                    </ul>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <ul class="service-article-list">
                      <li>
                        <i class="bx bxs-check-circle"></i>Payment gateway for third party integration
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Secure transactions world wide
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i> Convenient for small and large businesses
                      </li>
                    </ul>
                  </div>
                </div>
                <p style="text-align:justify">
                  The convenience and security associated with these payment modes is an 
                  important factor in the surge. With the recent introduction of several digital 
                  modes of payment in India, business owners are slowly adapting to the shift.
                  In cashless transactions, payments are made or accepted without the use of hard cash. 
                  This includes payments made via credit/debit cards, cheques, DD, NEFT, RTGS or 
                  any other form of online payment that removes the need for cash.
                </p>
              </div>
              <div class="service-article-another">
                <h2>What Benefit You Will Get</h2>
                <p style="text-align:justify">
                  As a business owner, customer satisfaction is your main goal. This is because you 
                  want your customers to have a great experience and keep coming back to do business with you. 
                  But processing the customer payments can become an arduous and time consuming process. 
                  In most cases, this is because the payment options you’ve offered are more convenient for you 
                  than your customers.
                </p>
                <div class="row">
                  <div class="col-lg-6 col-sm-6">
                    <div class="service-article-another-img">
                      <img
                        src="/assets/images/services/service-details-img1.jpg"
                        alt="Images"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="service-article-another-img">
                      <img
                        src="/assets/images/services/service-details-img2.jpg"
                        alt="Images"
                      />
                    </div>
                  </div>
                </div>
                <p style="text-align:justify">
                  Offering a variety of payment options will allow customers to choose their preferred method of 
                  payment, which will get you paid faster. Adding online payments to your options gives your customers 
                  the flexibility to pay through the option that is most convenient for them. Business owners and 
                  consumers can both benefit from digital payment modes in various ways.
                </p>
              </div>
              <div class="service-work-process">
                <h2>Advantages of Payment Systems</h2>
             
                    <h3>Convenience</h3>
                    <p style="text-align:justify">

                      The feasibility of making and receiving payments is the key factor for prioritizing digital 
                      payments. Online payments rule out the necessity to carry cash, and they also save time, as 
                      business owners and customers no longer have to queue up for ATM services. Payment apps also help 
                      you keep track of your incoming and outgoing funds, which comes in handy while filing returns.
                    </p>

                    <h3>Security</h3>
                    <p style="text-align:justify">
                      Digital payment modes are made secure with varying levels of encryption and data authentication. 
                      Most payment modes have enabled two-factor authentication (TFA) to add an extra layer of security. 
                      Also, it’s always easier and safer to carry a smartphone rather than carrying wads of cash.
                    </p>

                    <h3>Discounts</h3>
                    <p style="text-align:justify">
                      To boost the move towards a cashless economy, the government has decided to offer incentives and 
                      discounts for making online payments. The government is now providing waivers on cashless transactions 
                      utilised for service tax payments, purchase of fuel, train tickets, highway toll tickets, and 
                      insurance schemes.
                    </p>
            
              </div>
            </div>
          </div>
          <ServiceSidebar />
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import ServiceSidebar from '@/components/ServiceSidebar.vue'
export default {
    name: 'PaymentSystemsDetails',
    components: {
      ServiceSidebar
    }
}
</script>