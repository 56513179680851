<template>
  <div>
    <div class="banner-area">
      <div class="container-fluid">
        <div class="container-max">
          <div class="banner-item-content banner-item-ptb">
            <h1>We Provide Best Software Services for Your Need</h1>
            <p>
              From Saas(Software as a service) to Paas(Platform as a service)
              and any other innovative software, we have all the resources to
              deliver on budget and on time.
            </p>
            <div class="banner-btn">
              <router-link
                to="/services/app-development"
                class="default-btn btn-bg-two border-radius-50"
                >Learn More <i class="bx bx-chevron-right"></i
              ></router-link>
              <router-link
                to="/contacts"
                class="default-btn btn-bg-one border-radius-50 ml-20"
                >Contact Us <i class="bx bx-chevron-right"></i
              ></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="about-area ptb-100">
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-6">
            <div class="about-play">
              <img
                src="/assets/images/about/about-img1.jpg"
                alt="About Images"
              />
              <div class="about-play-content">
                <span>Watch Our Intro Video</span>
                <h2>Perfect Software Solutions!</h2>
                <div class="play-on-area">
                  <a
                    href="https://www.youtube.com/watch?v=tUP5S4YdEJo"
                    class="play-on popup-btn"
                    ><i class="bx bx-play"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-content ml-25">
              <div class="section-title">
                <span class="sp-color2">2 Years of Experience</span>
                <h2>Right Partner for Software Innovation</h2>
                <p>
                  An innovative idea is good, but a software that transforms an
                  innovative idea into a product is a better. That's why we are
                  specialized in software innovation.
                </p>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <ul class="about-list text-start">
                    <li><i class="bx bxs-check-circle"></i>Team of Experts</li>
                    <li>
                      <i class="bx bxs-check-circle"></i>
                      Experienced in various Software types
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>
                      Time and budget prioritization
                    </li>
                  </ul>
                </div>
                <div class="col-lg-6 col-md-6">
                  <ul class="about-list text-start about-list-2">
                    <li>
                      <i class="bx bxs-check-circle"></i>Understanding of
                      client's needs
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Implementing efficient
                      and reliable softwares
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Building Softwares for
                      all industries
                    </li>
                  </ul>
                </div>
              </div>
              <p class="about-content-text">
                Have you been struggling with finding the right IT Services
                organization that will respond quickly to your business' needs?
                Don't look further, DARA TECH is the perfect solution.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="services-area-two pt-100 pb-70">
      <div class="container">
        <div class="section-title text-center">
          <span class="sp-color1">Our Services</span>
          <h2>We Provide a Wide Variety of IT Services</h2>
        </div>
        <div class="row pt-45">
          <div class="col-lg-4 col-md-6">
            <div class="services-item">
              <router-link to="/services/it-consulting">
                <img
                  src="/assets/images/services/services-img1.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <i class="flaticon-consultant"></i>
                <span
                  ><router-link to="/services/it-consulting"
                    >IT Solution</router-link
                  ></span
                >
                <h3>
                  <router-link to="/services/it-consulting"
                    >IT Consulting</router-link
                  >
                </h3>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="services-item">
              <router-link to="/services/cloud-computing">
                <img
                  src="/assets/images/services/services-img2.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <i class="flaticon-consulting"></i>
                <span
                  ><router-link to="/services/cloud-computing"
                    >Cloud Services</router-link
                  ></span
                >
                <h3>
                  <router-link to="/services/cloud-computing"
                    >Cloud Computing</router-link
                  >
                </h3>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="services-item">
              <router-link to="/services/app-development">
                <img
                  src="/assets/images/services/services-img3.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <i class="flaticon-web-development"></i>
                <span
                  ><router-link to="/services/app-development"
                    >Web Service</router-link
                  ></span
                >
                <h3>
                  <router-link to="/services/app-development"
                    >App Development</router-link
                  >
                </h3>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="services-item">
              <router-link to="/services/system-security">
                <img
                  src="/assets/images/services/services-img4.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <i class="flaticon-cyber-security"></i>
                <span
                  ><router-link to="/services/system-security"
                    >Security</router-link
                  ></span
                >
                <h3>
                  <router-link to="/services/system-security"
                    >System Security</router-link
                  >
                </h3>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="services-item">
              <router-link to="/services/blockchain">
                <img
                  src="/assets/images/services/services-img5.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <i class="flaticon-structure"></i>
                <span
                  ><router-link to="/services/blockchain"
                    >Cryptocurrency</router-link
                  ></span
                >
                <h3>
                  <router-link to="/services/blockchain"
                    >Blockchain</router-link
                  >
                </h3>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="services-item">
              <router-link to="/services/data-analysis">
                <img
                  src="/assets/images/services/services-img6.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <i class="flaticon-data-analytics"></i>
                <span
                  ><router-link to="/services/data-analysis"
                    >Analysis</router-link
                  ></span
                >
                <h3>
                  <router-link to="/services/data-analysis"
                    >Data Analysis</router-link
                  >
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="work-process-area-two pt-100 pb-70">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-5">
            <div class="work-process-left">
              <div class="section-title">
                <span class="sp-color1">Our Working Process</span>
                <h2>How Our Services Will Help You to Grow Your Business</h2>
              </div>
              <router-link
                to="/contacts"
                class="default-btn btn-bg-two border-radius-50 text-center"
                >Contact Us</router-link
              >
            </div>
          </div>
          <div class="col-lg-7">
            <div class="row">
              <div class="col-lg-6 col-sm-6">
                <div class="work-process-card">
                  <i class="flaticon-project"></i>
                  <h3>Discovery</h3>
                  <p>
                    We explore the various ways to provide an efficient solution
                    for your business needs.
                  </p>
                  <div class="number">01</div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6">
                <div class="work-process-card">
                  <i class="flaticon-chip"></i>
                  <h3>Planning</h3>
                  <p>
                    We organize and plan the development cycle of the solution
                    to be implemented
                  </p>
                  <div class="number">02</div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6">
                <div class="work-process-card">
                  <i class="flaticon-effective"></i>
                  <h3>Execute</h3>
                  <p>
                    When tasks are been dispatched, execution follows with a
                    goal oriented strategy
                  </p>
                  <div class="number">03</div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6">
                <div class="work-process-card">
                  <i class="flaticon-bullhorn"></i>
                  <h3>Deliver</h3>
                  <p>
                    Budget and time are the most important factors we take into
                    account for project delivery
                  </p>
                  <div class="number">04</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="choose-area pt-100 pb-70">
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-6">
            <div class="choose-content mr-20">
              <div class="section-title">
                <span class="sp-color1">Why Choose Us</span>
                <h2>We Provide Truly Prominent IT Solutions.</h2>
                <p>
                  Because we want your business to standout in the thoughest
                  competition, so we take the responsibility to provide you with
                  prominent and sophisticated solutions.
                </p>
              </div>
              <div class="row">
                <div class="col-lg-6 col-6">
                  <div class="choose-content-card">
                    <div class="content">
                      <i class="flaticon-practice"></i>
                      <h3>Experience</h3>
                    </div>
                    <p>That's the professional style of our team of experts</p>
                  </div>
                </div>
                <div class="col-lg-6 col-6">
                  <div class="choose-content-card">
                    <div class="content">
                      <i class="flaticon-help"></i>
                      <h3>Quick Support</h3>
                    </div>
                    <p>Our support team is available 24/7 to assist you.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="choose-img">
              <img src="/assets/images/choose-img.jpg" alt="Images" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="build-area-on pt-100 pb-70">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-8">
            <div class="build-content build-content-on-color">
              <div class="section-title">
                <span>We Carry More Than Just Good Coding Skills</span>
                <h2>Let's Build Your Innovative Software!</h2>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="build-btn-area">
              <router-link
                to="/contacts"
                class="default-btn btn-bg-two border-radius-50"
                >Contact Us <i class="bx bx-chevron-right"></i
              ></router-link>
            </div>
          </div>
        </div>
        <div class="row pt-45">
          <div class="col-lg-4 col-md-6">
            <div class="build-item">
              <router-link to="/services/app-development">
                <img src="/assets/images/build/build-img1.jpg" alt="Images" />
              </router-link>
              <div class="content">
                <h3>
                  <router-link to="/services/app-development"
                    >Web App Development</router-link
                  >
                </h3>
                <router-link to="/services/app-development" class="more-btn"
                  ><i class="bx bx-right-arrow-alt"></i
                ></router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="build-item">
              <router-link to="/services/app-development">
                <img src="/assets/images/build/build-img2.jpg" alt="Images" />
              </router-link>
              <div class="content">
                <h3>
                  <router-link to="/services/app-development"
                    >Mobile App Development</router-link
                  >
                </h3>
                <router-link to="/services/app-development" class="more-btn"
                  ><i class="bx bx-right-arrow-alt"></i
                ></router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
            <div class="build-item">
              <router-link to="/services/app-development">
                <img src="/assets/images/build/build-img3.jpg" alt="Images" />
              </router-link>
              <div class="content">
                <h3>
                  <router-link to="/services/app-development"
                    >Desktop App Development</router-link
                  >
                </h3>
                <router-link to="/services/app-development" class="more-btn"
                  ><i class="bx bx-right-arrow-alt"></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="call-us-area">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="call-contact">
              <h3>Call Us 24/7</h3>
              <a href="tel:+285261384110" class="call-btn">(+852) 61384110</a>
              <p>
                For enquiries or technical and innovative solutions for your
                business, our team is always available for you.
              </p>
              <router-link
                to="/contacts"
                class="default-btn btn-bg-two border-radius-5"
                >Contact Us
              </router-link>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="call-us-img">
              <img src="/assets/images/call-us/call-us-img2.png" alt="Images" />
              <div class="call-shape">
                <div class="shape1">
                  <img
                    src="/assets/images/call-us/call-shap2.png"
                    alt="Images"
                  />
                </div>
                <div class="shape2">
                  <img
                    src="/assets/images/call-us/call-shap3.png"
                    alt="Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="counter-area pt-100 pb-70">
      <div class="container">
        <div class="section-title text-center">
          <span class="sp-color2">Numbers Are Talking</span>
          <h2>Let’s Check Our Business Growth and Success Story</h2>
          <p>
            We are not yet satsified with our achievement. We are ready to do
            more.
          </p>
        </div>
        <div class="row pt-45">
          <div class="col-lg-3 col-6 col-md-3">
            <div class="counter-another-content">
              <i class="flaticon-web-development"></i>
              <h3>4205+</h3>
              <span>Lines of code</span>
            </div>
          </div>
          <div class="col-lg-3 col-6 col-md-3">
            <div class="counter-another-content">
              <i class="flaticon-consulting-1"></i>
              <h3>5+</h3>
              <span>IT Consulting</span>
            </div>
          </div>
          <div class="col-lg-3 col-6 col-md-3">
            <div class="counter-another-content">
              <i class="flaticon-startup"></i>
              <h3>2+</h3>
              <span>Fully Launched</span>
            </div>
          </div>
          <div class="col-lg-3 col-6 col-md-3">
            <div class="counter-another-content">
              <i class="flaticon-tick"></i>
              <h3>2+</h3>
              <span>Project Completed</span>
            </div>
          </div>
        </div>
      </div>
      <div class="counter-shape">
        <div class="shape1">
          <img src="/assets/images/shape/shape1.png" alt="Images" />
        </div>
        <div class="shape2">
          <img src="/assets/images/shape/shape2.png" alt="Images" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Softwares",
};
</script>