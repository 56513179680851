<template>
    <div>
      <div class="inner-banner">
      <div class="container">
        <div class="inner-title text-center">
          <h3>Services</h3>
          <ul>
            <li>
              <router-link to="/services">Services</router-link>
            </li>
            <li>
              <i class="bx bx-chevrons-right"></i>
            </li>
            <li>Services</li>
          </ul>
        </div>
      </div>
      <div class="inner-shape">
        <img src="/assets/images/shape/inner-shape.png" alt="Images" />
      </div>
    </div>

    <section class="services-style-area pt-100 pb-70">
      <div class="container">
        <div class="section-title text-center">
          <span class="sp-color2">Our Services</span>
          <h2>We Provide a Wide Variety of It Services</h2>
          <p class="margin-auto">
            We go beyond the simple thoughts of what can be done and provide what should be done.
            The number of our IT services is by no means limited to only what you read.
          </p>
        </div>
        <div class="row pt-45">
          <div class="col-lg-3 col-sm-6">
            <div class="services-card services-style-bg">
              <i class="flaticon-consultant"></i>
              <h3><router-link to="/services/it-consulting">IT Consulting</router-link></h3>
              <p>
                We direct your choice and help you implementing the appropriate IT infrastructure 
                needed for your business
              </p>
              <router-link to="/services/it-consulting" class="learn-btn"
                >Learn More <i class="bx bx-chevron-right"></i
              ></router-link>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="services-card services-style-bg">
              <i class="flaticon-consulting"></i>
              <h3><router-link to="/services/cloud-computing">Cloud Computing</router-link></h3>
              <p>
                We help you saving maintenance cost and preventing your IT infrastructure from 
                natural disasters.
              </p>
              <router-link to="/services/cloud-computing" class="learn-btn"
                >Learn More <i class="bx bx-chevron-right"></i
              ></router-link>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="services-card services-style-bg">
              <i class="flaticon-web-development"></i>
              <h3><router-link to="/services/app-development">App Development</router-link></h3>
              <p>
                Either web, mobile or desktop application, we can transform any innovative idea 
                into a reliable software system.
              </p>
              <router-link to="/services/app-development" class="learn-btn"
                >Learn More <i class="bx bx-chevron-right"></i
              ></router-link>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="services-card services-style-bg">
              <i class="flaticon-consultant"></i>
              <h3><router-link to="/services/payment-systems">Payment Systems</router-link></h3>
              <p>
                The world is going cashless. So, we provide to businesses and financial institutions
                the systems to send and receive payments.
              </p>
              <router-link to="/services/payment-systems" class="learn-btn"
                >Learn More <i class="bx bx-chevron-right"></i
              ></router-link>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="services-card services-style-bg">
              <i class="flaticon-structure"></i>
              <h3><router-link to="/services/blockchain">Blockchain</router-link></h3>
              <p>
                Blockchain technology can be used to create a permanent, public, transparent ledger
                system for compiling data on sales.
              </p>
              <router-link to="/services/blockchain" class="learn-btn"
                >Learn More <i class="bx bx-chevron-right"></i
              ></router-link>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="services-card services-style-bg">
              <i class="flaticon-data-analytics"></i>
              <h3><router-link to="/services/data-analysis">Data Analysis</router-link></h3>
              <p>
                To improve the product or service accpetance by customers, you need to understand the customers
              </p>
              <router-link to="/services/data-analysis" class="learn-btn"
                >Learn More <i class="bx bx-chevron-right"></i
              ></router-link>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="services-card services-style-bg">
              <i class="flaticon-computer"></i>
              <h3><router-link to="/services/machine-learning">Machine Learning</router-link></h3>
              <p>
                Computers discovering how they can perform tasks without being explicitly programmed to do so.
                For machine heavy organizations.
              </p>
              <router-link to="/services/machine-learning" class="learn-btn"
                >Learn More <i class="bx bx-chevron-right"></i
              ></router-link>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="services-card services-style-bg">
              <i class="flaticon-cyber-security"></i>
              <h3><router-link to="/services/system-security">System Security</router-link></h3>
              <p>
                We help you securing your IT systems against malicious users. Penetration and 
                infiltration testing are our basics.
              </p>
              <router-link to="/services/system-security" class="learn-btn"
                >Learn More <i class="bx bx-chevron-right"></i
              ></router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
</template>

<script>
export default {
    name: 'Service2'
}
</script>