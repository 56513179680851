<template>
     <div>
       <div class="inner-banner">
      <div class="container">
        <div class="inner-title text-center">
          <h3>Cloud Computing</h3>
          <ul>
            <li>
              <router-link to="/services">Services</router-link>
            </li>
            <li>
              <i class="bx bx-chevrons-right"></i>
            </li>
            <li>Cloud Computing</li>
          </ul>
        </div>
      </div>
      <div class="inner-shape">
        <img src="/assets/images/shape/inner-shape.png" alt="Images" />
      </div>
    </div>

    <div class="service-details-area pt-100 pb-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="service-article">
              <div class="service-article-img">
                <img
                  src="/assets/images/services/service-details.jpg"
                  alt="Images"
                />
              </div>
              <div class="service-article-content">
                <h2>Cloud Computing</h2>
                <p style="text-align:justify">
                  Cloud computing[1] is the on-demand availability of computer system resources, especially 
                  data storage (cloud storage) and computing power, without direct active management by the 
                  user.[2] Large clouds often have functions distributed over multiple locations, each 
                  location being a data center. Cloud computing relies on sharing of resources to achieve 
                  coherence[clarification needed] and economies of scale, typically using a "pay-as-you-go" 
                  model which can help in reducing capital expenses but may also lead to unexpected 
                  operating expenses for unaware users.[3]
                </p>
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <ul class="service-article-list service-article-rs">
                      <li>
                        <i class="bx bxs-check-circle"></i>Avoiding or minimizing up-front IT infrastructure costs. 
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Getting applications up and running faster,
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Improving manageability and less maintenance
                      </li>
                    </ul>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <ul class="service-article-list">
                      <li>
                        <i class="bx bxs-check-circle"></i>Rapidly adjusting resources to meet fluctuating and unpredictable demand
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>High computing power at certain periods of peak demand
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i> Sharing of resources to achieve coherence
                      </li>
                    </ul>
                  </div>
                </div>
                <p style="text-align:justify">
                  The cloud symbol was used to represent networks of computing equipment in the original 
                  ARPANET by as early as 1977,[9] and the CSNET by 1981[10]—both predecessors to the 
                  Internet itself. The word cloud was used as a metaphor for the Internet and a 
                  standardized cloud-like shape was used to denote a network on telephony schematics. 
                  With this simplification, the implication is that the specifics of how the endpoints of a network are connected are not relevant to understanding the diagram.[11]
                </p>
              </div>
              <div class="service-article-another">
                <h2>What Benefit You Will Get</h2>
                <p style="text-align:justify">
                  Advocates of public and hybrid clouds note that cloud computing allows companies to avoid or 
                  minimize up-front IT infrastructure costs. Proponents also claim that cloud computing 
                  allows enterprises to get their applications up and running faster, with improved 
                  manageability and less maintenance, and that it enables IT teams to more rapidly adjust 
                  resources to meet fluctuating and unpredictable demand,[4][5][6] providing the burst 
                  computing capability: high computing power at certain periods of peak demand.[7]
                </p>
                <div class="row">
                  <div class="col-lg-6 col-sm-6">
                    <div class="service-article-another-img">
                      <img
                        src="/assets/images/services/service-details-img1.jpg"
                        alt="Images"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="service-article-another-img">
                      <img
                        src="/assets/images/services/service-details-img2.jpg"
                        alt="Images"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="service-work-process">
                <h2>Advantages of Cloud Computing</h2>
                <p style="text-align:justify">
                  The goal of cloud computing is to allow users to take benefit from all of these 
                  technologies, without the need for deep knowledge about or expertise with each one of 
                  them. The cloud aims to cut costs and helps the users focus on their core business 
                  instead of being impeded by IT obstacles.[48] The main enabling technology for cloud 
                  computing is virtualization. Virtualization software separates a physical computing 
                  device into one or more "virtual" devices, each of which can be easily used and managed 
                  to perform computing tasks.
                </p>
              </div>
            </div>
          </div>
          <ServiceSidebar />
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import ServiceSidebar from '@/components/ServiceSidebar.vue'
export default {
    name: 'CloudComputingDetails',
    components: {
      ServiceSidebar
    }
}
</script>