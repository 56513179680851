<template>
    <div>
      <div class="inner-banner">
      <div class="container">
        <div class="inner-title text-center">
          <h3>Case Study</h3>
          <ul>
            <li>
              <a href="index.html">Home</a>
            </li>
            <li>
              <i class="bx bx-chevrons-right"></i>
            </li>
            <li>Case Study</li>
          </ul>
        </div>
      </div>
      <div class="inner-shape">
        <img src="/assets/images/shape/inner-shape.png" alt="Images" />
      </div>
    </div>

    <div class="case-study-area pt-100 pb-70">
      <div class="container">
        <div class="section-title text-center">
          <span class="sp-color2">Case Study</span>
          <h2>Introduce Our Projects and Check Recent Work</h2>
        </div>
        <div class="row pt-45">
          <div class="col-lg-4 col-md-6">
            <div class="case-study-item">
              <a href="case-details.html">
                <img
                  src="/assets/images/case-study/case-study1.jpg"
                  alt="Images"
                />
              </a>
              <div class="content">
                <h3><a href="case-details.html">System Security</a></h3>
                <ul>
                  <li><a href="case-details.html">Penetration</a></li>
                  <li><a href="case-details.html">Defense</a></li>
                </ul>
                <a href="case-details.html" class="more-btn"
                  ><i class="bx bx-right-arrow-alt"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="case-study-item">
              <a href="case-details.html">
                <img
                  src="/assets/images/case-study/case-study2.jpg"
                  alt="Images"
                />
              </a>
              <div class="content">
                <h3><a href="case-details.html">App Development</a></h3>
                <ul>
                  <li><a href="case-details.html">Web</a></li>
                  <li><a href="case-details.html">Mobile</a></li>
                  <li><a href="case-details.html">Desktop</a></li>
                </ul>
                <a href="case-details.html" class="more-btn"
                  ><i class="bx bx-right-arrow-alt"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="case-study-item">
              <a href="case-details.html">
                <img
                  src="/assets/images/case-study/case-study3.jpg"
                  alt="Images"
                />
              </a>
              <div class="content">
                <h3><a href="case-details.html">Data Analysis</a></h3>
                <ul>
                  <li><a href="case-details.html">Data Collection</a></li>
                  <li><a href="case-details.html">Statistics</a></li>
                </ul>
                <a href="case-details.html" class="more-btn"
                  ><i class="bx bx-right-arrow-alt"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="case-study-item">
              <router-link to="/services/cryptocurrency/blockchain">
                <img
                  src="/assets/images/case-study/case-study4.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <h3><router-link to="/services/cryptocurrency/blockchain">Cryptocurrency</router-link></h3>
                <ul>
                  <li><router-link to="/services/cryptocurrency/blockchain">Blockchain</router-link></li>
                </ul>
                <router-link to="/services/cryptocurrency/blockchain" class="more-btn"
                  ><i class="bx bx-right-arrow-alt"></i
                ></router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="case-study-item">
              <a href="case-details.html">
                <img
                  src="/assets/images/case-study/case-study5.jpg"
                  alt="Images"
                />
              </a>
              <div class="content">
                <h3><a href="case-details.html">Payment Systems</a></h3>
                <ul>
                  <li><a href="case-details.html">Remittance</a></li>
                  <li><a href="case-details.html">Point of Sale(POS)</a></li>
                </ul>
                <a href="case-details.html" class="more-btn"
                  ><i class="bx bx-right-arrow-alt"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="case-study-item">
              <a href="case-details.html">
                <img
                  src="/assets/images/case-study/case-study6.jpg"
                  alt="Images"
                />
              </a>
              <div class="content">
                <h3><a href="case-details.html">IT Consulting</a></h3>
                <ul>
                  <li><a href="case-details.html">Advice</a></li>
                  <li><a href="case-details.html">Planning</a></li>
                </ul>
                <a href="case-details.html" class="more-btn"
                  ><i class="bx bx-right-arrow-alt"></i
                ></a>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    </div>
</template>

<script>
export default {
    name: 'CaseStudy'
}
</script>