<template>
     <div>
       <div class="inner-banner">
      <div class="container">
        <div class="inner-title text-center">
          <h3>Machine Learning</h3>
          <ul>
            <li>
              <router-link to="/services">Services</router-link>
            </li>
            <li>
              <i class="bx bx-chevrons-right"></i>
            </li>
            <li>Machine Learning</li>
          </ul>
        </div>
      </div>
      <div class="inner-shape">
        <img src="/assets/images/shape/inner-shape.png" alt="Images" />
      </div>
    </div>

    <div class="service-details-area pt-100 pb-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="service-article">
              <div class="service-article-img">
                <img
                  src="/assets/images/services/service-details.jpg"
                  alt="Images"
                />
              </div>
              <div class="service-article-content">
                <h2>Machine Learning</h2>
                <p style="text-align:justify">
                  Machine learning (ML) is the study of computer algorithms that can improve automatically 
                  through experience and by the use of data. It is seen as a part of artificial 
                  intelligence. Machine learning algorithms build a model based on sample data, 
                  known as "training data", in order to make predictions or decisions without being 
                  explicitly programmed to do so. Machine learning algorithms are used in a wide 
                  variety of applications, such as in medicine, email filtering, speech recognition, 
                  and computer vision, where it is difficult or unfeasible to develop conventional 
                  algorithms to perform the needed tasks.
                </p>
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <ul class="service-article-list service-article-rs">
                      <li>
                        <i class="bx bxs-check-circle"></i>Supervised Learning
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Unsupervised Learning
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Semi-supervised Learning
                      </li>
                    </ul>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <ul class="service-article-list">
                      <li>
                        <i class="bx bxs-check-circle"></i>Reinforcement Learning
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Dimensionality Reduction
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i> Robot learning
                      </li>
                    </ul>
                  </div>
                </div>
                <p style="text-align:justify">
                  Machine learning involves computers discovering how they can perform tasks without being 
                  explicitly programmed to do so. It involves computers learning from data provided so that 
                  they carry out certain tasks. For simple tasks assigned to computers, it is possible to program 
                  algorithms telling the machine how to execute all steps required to solve the problem at hand; 
                  on the computer's part, no learning is needed. For more advanced tasks, it can be challenging for 
                  a human to manually create the needed algorithms. In practice, it can turn out to be more effective 
                  to help the machine develop its own algorithm, rather than having human programmers specify every 
                  needed step.
                </p>
              </div>
              <div class="service-article-another">
                <h2>Theory</h2>
                <div style="text-align:justify">
                  <p>
                    A core objective of a learner is to generalize from its experience.[4][40] Generalization in 
                    this context is the ability of a learning machine to perform accurately on new, unseen 
                    examples/tasks after having experienced a learning data set. The training examples come 
                    from some generally unknown probability distribution (considered representative of the space 
                    of occurrences) and the learner has to build a general model about this space that enables it to 
                    produce sufficiently accurate predictions in new cases.
                  </p>
                  <p>
                    The computational analysis of machine learning algorithms and their performance is a branch of 
                    theoretical computer science known as computational learning theory. Because training sets are 
                    finite and the future is uncertain, learning theory usually does not yield guarantees of the 
                    performance of algorithms. Instead, probabilistic bounds on the performance are quite common. 
                    The bias–variance decomposition is one way to quantify generalization error.
                  </p>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-sm-6">
                    <div class="service-article-another-img">
                      <img
                        src="/assets/images/services/service-details-img1.jpg"
                        alt="Images"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="service-article-another-img">
                      <img
                        src="/assets/images/services/service-details-img2.jpg"
                        alt="Images"
                      />
                    </div>
                  </div>
                </div>
                <div style="text-align:justify">
                  <p>
                    For the best performance in the context of generalization, the complexity of the hypothesis should 
                    match the complexity of the function underlying the data. If the hypothesis is less complex than 
                    the function, then the model has under fitted the data. If the complexity of the model is 
                    increased in response, then the training error decreases. But if the hypothesis is too complex, 
                    then the model is subject to overfitting and generalization will be poorer.[41]
                  </p>
                  <p>
                    In addition to performance bounds, learning theorists study the time complexity and feasibility of 
                    learning. In computational learning theory, a computation is considered feasible if it can be done 
                    in polynomial time. There are two kinds of time complexity results. Positive results show that a 
                    certain class of functions can be learned in polynomial time. Negative results show that certain 
                    classes cannot be learned in polynomial time.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <ServiceSidebar />
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import ServiceSidebar from '@/components/ServiceSidebar.vue'
export default {
    name: 'MachineLearningDetails',
    components: {
      ServiceSidebar
    }
}
</script>