<template>
     <div>
       <div class="inner-banner">
      <div class="container">
        <div class="inner-title text-center">
          <h3>Data Analysis</h3>
          <ul>
            <li>
              <router-link to="/services">Services</router-link>
            </li>
            <li>
              <i class="bx bx-chevrons-right"></i>
            </li>
            <li>Data Analysis</li>
          </ul>
        </div>
      </div>
      <div class="inner-shape">
        <img src="/assets/images/shape/inner-shape.png" alt="Images" />
      </div>
    </div>

    <div class="service-details-area pt-100 pb-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="service-article">
              <div class="service-article-img">
                <img
                  src="/assets/images/services/service-details.jpg"
                  alt="Images"
                />
              </div>
              <div class="service-article-content">
                <h2>Data Analysis</h2>
                <p>
                  Data analysis is a process of inspecting, cleansing, transforming, and modelling data with the 
                  goal of discovering useful information, informing conclusions, and supporting decision-making.
                  Data analysis has multiple facets and approaches, encompassing diverse techniques under a variety 
                  of names, and is used in different business, science, and social science domains. 
                  In today's business world, data analysis plays a role in making decisions more scientific and helping businesses operate more effectively.[3]
                </p>
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <ul class="service-article-list service-article-rs">
                      <li>
                        <i class="bx bxs-check-circle"></i>Easy and simple cashless transactions
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Fast and reliable payment systems
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Remittance into mobile wallet and bank account
                      </li>
                    </ul>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <ul class="service-article-list">
                      <li>
                        <i class="bx bxs-check-circle"></i>Payment gateway for third party integration
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Secure transactions world wide
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i> Convenient for small and large businesses
                      </li>
                    </ul>
                  </div>
                </div>
                <p>
                  Data requirements
The data is necessary as inputs to the analysis, which is specified based upon the requirements of those directing the analysis (or customers, who will use the finished product of the analysis).[14][15] The general type of entity upon which the data will be collected is referred to as an experimental unit (e.g., a person or population of people). Specific variables regarding a population (e.g., age and income) may be specified and obtained. Data may be numerical or categorical (i.e., a text label for numbers).[13]

Data collection
Data is collected from a variety of sources.[16][17] The requirements may be communicated by analysts to custodians of the data; such as, Information Technology personnel within an organization.[18] The data may also be collected from sensors in the environment, including traffic cameras, satellites, recording devices, etc. It may also be obtained through interviews, downloads from online sources, or reading documentation.[13]

Data processing

The phases of the intelligence cycle used to convert raw information into actionable intelligence or knowledge are conceptually similar to the phases in data analysis.
Data, when initially obtained, must be processed or organized for analysis.[19][20] For instance, these may involve placing data into rows and columns in a table format (known as structured data) for further analysis, often through the use of spreadsheet or statistical software.[13]
Data cleaning
Main article: Data cleansing
Once processed and organized, the data may be incomplete, contain duplicates, or contain errors.[21][22] The need for data cleaning will arise from problems in the way that the datum are entered and stored.[21] Data cleaning is the process of preventing and correcting these errors.
                </p>
              </div>
              
              <div class="service-work-process">
                <h2>Advantages of Data Analysis</h2>
                  <p>
                    <b>Smart buildings</b><br />
                    A data analytics approach can be used in order to predict energy consumption in buildings.
                    The different steps of the data analysis process are carried out in order to realise smart 
                    buildings, where the building management and control operations including heating, ventilation, 
                    air conditioning, lighting and security are realised automatically by miming the needs of the 
                    building users and optimising resources like energy and time.
                  </p>
                  <p>
                    <b>Analytics and business intelligence</b><br />
                    Analytics is the "extensive use of data, statistical and quantitative analysis, explanatory and 
                    predictive models, and fact-based management to drive decisions and actions." It is a subset of 
                    business intelligence, which is a set of technologies and processes that uses data to understand 
                    and analyze business performance to drive decision-making.
                  </p>
                  <p>
                    <b>Education</b><br />
                    In education, most educators have access to a data system for the purpose of analyzing student data.
                    These data systems present data to educators in an over-the-counter data format 
                    (embedding labels, supplemental documentation, and a help system and making key package/display and 
                    content decisions) to improve the accuracy of educators’ data analyses.
                  </p>
            
              </div>
            </div>
          </div>
          <ServiceSidebar />
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import ServiceSidebar from '@/components/ServiceSidebar.vue'
export default {
    name: 'DataAnalysisDetails',
    components: {
      ServiceSidebar
    }
}
</script>