<template>
    <div>
      <div class="inner-banner">
      <div class="container">
        <div class="inner-title text-center">
          <h3>Team</h3>
          <ul>
            <li>
              <router-link to="/">Home</router-link>
            </li>
            <li>
              <i class="bx bx-chevrons-right"></i>
            </li>
            <li>Team</li>
          </ul>
        </div>
      </div>
      <div class="inner-shape">
        <img src="/assets/images/shape/inner-shape.png" alt="Images" />
      </div>
    </div>

    <div class="team-area pt-100 pb-70">
      <div class="container">
        <div class="section-title text-center">
          <span class="sp-color2">Our Team</span>
          <h2>Our Team Members</h2>
        </div>
        <div class="row pt-45">
          <div class="col-lg-4 col-md-6">
            <div class="team-card">
              <img src="/assets/images/team/team-img1.jpg" alt="Team Images" />
              <ul class="social-link">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="bx bxl-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/?lang=en" target="_blank">
                    <i class="bx bxl-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="bx bxl-linkedin-square"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/" target="_blank">
                    <i class="bx bxl-instagram"></i>
                  </a>
                </li>
              </ul>
              <div class="content">
                <h3>Ulrich Fangnikoue</h3>
                <span>Executive Director</span>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="team-card">
              <img src="/assets/images/team/team-img2.jpg" alt="Team Images" />
              <ul class="social-link">
                <li>
                  <a href="https://twitter.com/tomakponvi" target="_blank">
                    <i class="bx bxl-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/in/tom-avoulete-kponvi-7a4270190/" target="_blank">
                    <i class="bx bxl-linkedin-square"></i>
                  </a>
                </li>
              </ul>
              <div class="content">
                <h3>Tom A. Kponvi</h3>
                <span>Lead Engineer</span>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="team-card">
              <img src="/assets/images/team/team-img3.jpg" alt="Team Images" />
              <ul class="social-link">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="bx bxl-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/?lang=en" target="_blank">
                    <i class="bx bxl-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="bx bxl-linkedin-square"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/" target="_blank">
                    <i class="bx bxl-instagram"></i>
                  </a>
                </li>
              </ul>
              <div class="content">
                <h3>Freeman Dossou</h3>
                <span>UI/UX Designer</span>
              </div>
            </div>
          </div>
         
          
        </div>
      </div>
    </div>
    </div>
</template>
<script>
export default {
    name: 'Team'
}
</script>