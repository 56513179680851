<template>
     <div>
       <div class="inner-banner">
      <div class="container">
        <div class="inner-title text-center">
          <h3>Forget Password</h3>
          <ul>
            <li>
              <a href="index.html">Home</a>
            </li>
            <li>
              <i class="bx bx-chevrons-right"></i>
            </li>
            <li>Forget Password</li>
          </ul>
        </div>
      </div>
      <div class="inner-shape">
        <img src="/assets/images/shape/inner-shape.png" alt="Images" />
      </div>
    </div>

    <div class="user-area pt-100 pb-70">
      <div class="container">
        <div class="user-form">
          <div class="contact-form">
            <h2>Forget Password</h2>
            <form>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      required
                      data-error="Please enter username or email"
                      placeholder="Username or Email"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <button type="submit" class="default-btn btn-bg-two">
                    Reset Now
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>

<script>
export default {
    name: 'ForgetPassword'
}
</script>