<template>
  <div>
    <header class="top-header top-header-bg">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-7 col-md-6">
            <div class="top-head-left">
              <div class="top-contact">
                <h3>
                  Support By :
                  <a href="tel:+85261384110">(+852)61384110</a>
                </h3>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-6">
            <div class="top-header-right">
              <div class="top-header-social">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/" target="_blank">
                      <i class="bx bxl-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/?lang=en" target="_blank">
                      <i class="bx bxl-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/" target="_blank">
                      <i class="bx bxl-linkedin-square"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/" target="_blank">
                      <i class="bx bxl-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div class="navbar-area">
      <div class="mobile-nav">
        <router-link to="/" class="logo">
          <img src="/assets/images/logos/logo-1.png" alt="Logo" />
        </router-link>
      </div>

      <div class="main-nav">
        <div class="container">
          <nav class="navbar navbar-expand-md navbar-light">
            <router-link class="navbar-brand" to="/">
              <img src="/assets/images/logos/logo-1.png" alt="Logo" />
            </router-link>
            <div
              class="collapse navbar-collapse mean-menu"
              id="navbarSupportedContent"
            >
              <ul class="navbar-nav m-auto">
                <!-- <li class="nav-item">
                <a href="#" class="nav-link">
                  Home
                  <i class="bx bx-caret-down"></i>
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/" class="nav-link">
                      What We Do
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/home/home-2" class="nav-link"> Home Two </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/home/home-3" class="nav-link"> Home Three </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/home/home-4" class="nav-link">
                      Home Four <span>(New)</span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/home/home-5" class="nav-link">
                      Home Five <span>(New)</span>
                    </router-link>
                  </li>
                </ul>
              </li> -->
                <li class="nav-item">
                  <router-link to="/" class="nav-link"> Home </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/about-us" class="nav-link">
                    About
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/digitalization" class="nav-link">
                    Digitalization
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/it" class="nav-link"> IT </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/softwares" class="nav-link">
                    Softwares
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/innovation" class="nav-link">
                    Innovation
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/services" class="nav-link">
                    Services
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/contacts" class="nav-link">
                    Contacts
                  </router-link>
                </li>
              </ul>
              <div class="nav-side d-display">
                <div class="nav-side-item">
                  <div class="search-box">
                    <i class="bx bx-search"></i>
                  </div>
                </div>
                <div class="nav-side-item">
                  <div class="get-btn">
                    <router-link
                      to="/contacts"
                      class="default-btn btn-bg-two border-radius-50"
                      >Get In Touch <i class="bx bx-chevron-right"></i
                    ></router-link>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div class="side-nav-responsive">
        <div class="container-max">
          <div class="dot-menu">
            <div class="circle-inner">
              <div class="in-circle circle-one"></div>
              <div class="in-circle circle-two"></div>
              <div class="in-circle circle-three"></div>
            </div>
          </div>
          <div class="container">
            <div class="side-nav-inner">
              <div class="side-nav justify-content-center align-items-center">
                <div class="side-nav-item nav-side">
                  <div class="search-box">
                    <i class="bx bx-search"></i>
                  </div>
                  <div class="get-btn">
                    <router-link
                      to="/contacts"
                      class="default-btn btn-bg-two border-radius-50"
                      >Contact Us <i class="bx bx-chevron-right"></i
                    ></router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="search-overlay">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="search-layer"></div>
          <div class="search-layer"></div>
          <div class="search-layer"></div>
          <div class="search-close">
            <span class="search-close-line"></span>
            <span class="search-close-line"></span>
          </div>
          <div class="search-form">
            <form>
              <input
                type="text"
                class="input-search"
                placeholder="Search here..."
              />
              <button type="submit"><i class="bx bx-search"></i></button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopNav",
  methods: {
    redirectTo(router) {
      this.$router.push(router);
    },
  },
};
</script>
