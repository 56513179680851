export default { 
    methods: {
        successMessage(m) {
            this.$message({
                showClose: true,
                message: m,
                type: 'success'
            })
        }
    }
}