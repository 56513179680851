<template>
     <div>
       <div class="inner-banner">
      <div class="container">
        <div class="inner-title text-center">
          <h3>System Security</h3>
          <ul>
            <li>
              <router-link to="/services">Services</router-link>
            </li>
            <li>
              <i class="bx bx-chevrons-right"></i>
            </li>
            <li>System Security</li>
          </ul>
        </div>
      </div>
      <div class="inner-shape">
        <img src="/assets/images/shape/inner-shape.png" alt="Images" />
      </div>
    </div>

    <div class="service-details-area pt-100 pb-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="service-article">
              <div class="service-article-img">
                <img
                  src="/assets/images/services/service-details.jpg"
                  alt="Images"
                />
              </div>
              <div class="service-article-content">
                <h2>System Security</h2>
                <p>
                  Computer security, cybersecurity or information technology security (IT security) is the 
                  protection of computer systems and networks from information disclosure, theft of or 
                  damage to their hardware, software, or electronic data, as well as from the disruption or 
                  misdirection of the services they provide.
                </p>
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <ul class="service-article-list service-article-rs">
                      <li>
                        <i class="bx bxs-check-circle"></i>Security by design
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Security architecture
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Security measures
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Vulnerability management
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Reducing vulnerabilities
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Hardware protection mechanisms
                      </li>
                      
                    </ul>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <ul class="service-article-list">
                      <li>
                        <i class="bx bxs-check-circle"></i>Secure coding
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Capabilities and access control lists
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>End user security training
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Digital hygiene
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Response to breaches
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Secure operating systems
                      </li>
                    </ul>
                  </div>
                </div>
                <p>
                  A vulnerability is a weakness in design, implementation, operation, or internal control. 
                  Most of the vulnerabilities that have been discovered are documented in the Common 
                  Vulnerabilities and Exposures (CVE) database.[citation needed] An exploitable vulnerability 
                  is one for which at least one working attack or "exploit" exists. Vulnerabilities can be 
                  researched, reverse-engineered, hunted, or exploited using automated tools or customized 
                  scripts. To secure a computer system, it is important to understand the attacks that can be 
                  made against it, and these threats can typically be classified into one of these categories 
                  below:
                </p>
              </div>
              <div class="service-article-another">
                <h2>System at Risk</h2>
                <p>
                  The growth in the number of computer systems and the increasing reliance upon them by 
                  individuals, businesses, industries, and governments means that there is an increasing 
                  number of systems at risk.
                </p>

                <p>
                  <b>Financial systems</b>
                  The computer systems of financial regulators and financial institutions like the U.S. 
                  Securities and Exchange Commission, SWIFT, investment banks, and commercial banks are 
                  prominent hacking targets for cybercriminals interested in manipulating markets and making 
                  illicit gains.[33] Websites and apps that accept or store credit card numbers, brokerage 
                  accounts, and bank account information are also prominent hacking targets, because of the 
                  potential for immediate financial gain from transferring money, making purchases, or 
                  selling the information on the black market.[34] In-store payment systems and ATMs have 
                  also been tampered with in order to gather customer account data and PINs.
                </p>
                <p>
                  <b>Utilities and industrial equipment</b>
                  Computers control functions at many utilities, including coordination of 
                  telecommunications, the power grid, nuclear power plants, and valve opening 
                  and closing in water and gas networks. The Internet is a potential attack 
                  vector for such machines if connected, but the Stuxnet worm demonstrated 
                  that even equipment controlled by computers not connected to the Internet 
                  can be vulnerable. In 2014, the Computer Emergency Readiness Team, a division 
                  of the Department of Homeland Security, investigated 79 hacking incidents at 
                  energy companies.
                </p>
                <p>
                  <b>Aviation</b>
                  The aviation industry is very reliant on a series of complex systems which could be 
                  attacked. A simple power outage at one airport can cause repercussions worldwide, 
                  much of the system relies on radio transmissions which could be disrupted, and controlling 
                  aircraft over oceans is especially dangerous because radar surveillance only extends 175 to 
                  225 miles offshore.There is also potential for attack from within an aircraft.
                  In Europe, with the (Pan-European Network Service) and NewPENS, and in the US with the 
                  NextGen program, air navigation service providers are moving to create their own dedicated 
                  networks. The consequences of a successful attack range from loss of confidentiality to loss 
                  of system integrity, air traffic control outages, loss of aircraft, and even loss of life.
                </p>
                <p>
                  <b>Government</b>
                    Government and military computer systems are commonly attacked by activists and foreign powers.
                    Local and regional government infrastructure such as traffic light controls, police and 
                    intelligence agency communications, personnel records, student records, and financial 
                    systems are also potential targets as they are now all largely computerized. Passports and 
                    government ID cards that control access to facilities which use RFID can be vulnerable to 
                    cloning.
                </p>
                <div class="row">
                  <div class="col-lg-6 col-sm-6">
                    <div class="service-article-another-img">
                      <img
                        src="/assets/images/services/service-details-img1.jpg"
                        alt="Images"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="service-article-another-img">
                      <img
                        src="/assets/images/services/service-details-img2.jpg"
                        alt="Images"
                      />
                    </div>
                  </div>
                </div>
                <p>
                  <b>Consumer devices</b>
                  Desktop computers and laptops are commonly targeted to gather passwords or f
                  inancial account information, or to construct a botnet to attack another target. 
                  Smartphones, tablet computers, smart watches, and other mobile devices such as 
                  quantified self devices like activity trackers have sensors such as cameras, 
                  microphones, GPS receivers, compasses, and accelerometers which could be exploited, 
                  and may collect personal information, including sensitive health information. WiFi, 
                  Bluetooth, and cell phone networks on any of these devices could be used as attack 
                  vectors, and sensors might be remotely activated after a successful breach.

                  The increasing number of home automation devices such as the Nest thermostat are also 
                  potential targets.
                </p>
              </div>
            </div>
          </div>
          <ServiceSidebar />
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import ServiceSidebar from '@/components/ServiceSidebar.vue'

export default {
    name: 'SystemSecurityDetails',
    components: {
      ServiceSidebar
    }
}
</script>