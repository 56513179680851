<template>
    <div>
      <div class="banner-four-area">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="banner-four-content">
              <span>TECHNOLOGY & IT SOLUTIONS</span>
              <h1>Exceptional It Services Provider for Your Need</h1>
              <p>
                Because every business has a unique need, we provide customized IT Services that make your
                business standing against competition.
              </p>
              <div class="banner-btn">
                <router-link
                  to="/contacts"
                  class="default-btn btn-bg-two border-radius-50"
                  >Contact Us <i class="bx bx-chevron-right"></i
                ></router-link>
                <a
                  href="https://www.youtube.com/watch?v=tUP5S4YdEJo"
                  class="play-btn popup-btn ml-20"
                >
                  <i class="bx bx-play"></i>
                  <h3>Watch Video</h3>
                  <span>2 Minutes</span>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 pe-0">
            <div class="banner-four-img">
              <img
                src="/assets/images/home-four/home-four-img.png"
                alt="Images"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="choose-area-tow pt-100 pb-70">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="choose-content-two">
              <div class="section-title">
                <span class="sp-color2">Why Choose Us</span>
                <h2>2 Years of Experience for Software Innovation</h2>
                <p>
                  An innovative idea is good, but a software that transforms an innovative idea into a 
                  product is a better. That's why we are specialized in software innovation.
                </p>
              </div>
              <div class="row">
                <div class="col-lg-12 col-sm-6">
                  <div class="choose-content-card">
                    <i class="flaticon-practice"></i>
                    <h3>Experience</h3>
                    <p>
                      That's what makes innovative the softwares we build.
                    </p>
                  </div>
                </div>
                <div class="col-lg-12 col-sm-6">
                  <div class="choose-content-card">
                    <i class="flaticon-help"></i>
                    <h3>Quick Support</h3>
                    <p>
                      Our support team is available 24/7 to assist you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="choose-img-two">
              <img
                src="/assets/images/choose-img/choose-img1.jpg"
                alt="About Images"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="services-area-three ptb-100">
      <div class="container">
        <div class="section-title text-center">
          <span class="sp-color2">Our Services</span>
          <h2>We Provide a Wide Variety of IT Services</h2>
        </div>
        <div class="row pt-45">
          <div class="col-lg-4 col-md-6">
            <div class="services-item">
              <router-link to="/services/it-consulting">
                <img
                  src="/assets/images/services/services-img1.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <i class="flaticon-consultant"></i>
                <span><router-link to="/services/it-consulting">IT Solution</router-link></span>
                <h3><router-link to="/services/it-consulting">IT Consulting</router-link></h3>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="services-item">
              <router-link to="/services/cloud-computing">
                <img
                  src="/assets/images/services/services-img2.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <i class="flaticon-consulting"></i>
                <span><router-link to="/services/cloud-computing">Cloud Services</router-link></span>
                <h3><router-link to="/services/cloud-computing">Cloud Computing</router-link></h3>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="services-item">
              <router-link to="/services/app-development">
                <img
                  src="/assets/images/services/services-img3.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <i class="flaticon-web-development"></i>
                <span><router-link to="/services/app-development">Web Services</router-link></span>
                <h3><router-link to="/services/app-development">App Development</router-link></h3>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="services-item">
              <router-link to="/services/system-security">
                <img
                  src="/assets/images/services/services-img4.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <i class="flaticon-cyber-security"></i>
                <span><router-link to="/services/system-security">Security</router-link></span>
                <h3><router-link to="/services/system-security">System Security</router-link></h3>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="services-item">
              <router-link to="/services/blockchain">
                <img
                  src="/assets/images/services/services-img5.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <i class="flaticon-structure"></i>
                <span><router-link to="/services/blockchain">Cryptocurrency</router-link></span>
                <h3><router-link to="/services/blockchain">Blockchain</router-link></h3>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="services-item">
              <router-link to="/services/data-analysis">
                <img
                  src="/assets/images/services/services-img6.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <i class="flaticon-data-analytics"></i>
                <span><router-link to="/services/data-analysis">Analysis</router-link></span>
                <h3><router-link to="/services/data-analysis">Data Analysis</router-link></h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 text-center">
          <router-link
            to="/services/"
            class="default-btn btn-bg-two border-radius-50 text-center"
            >View All Services</router-link>
        </div>
      </div>
    </section>

    <div class="talk-area-two ptb-100">
      <div class="container">
        <div class="talk-content text-center">
          <div class="section-title text-center">
            <span class="sp-color1">Let's Talk</span>
            <h2>We Are Adding Kinds of IT Services That You Grow Success</h2>
          </div>
          <router-link to="/contacts/" class="default-btn btn-bg-one border-radius-5"
            >Contact Us</router-link>
        </div>
      </div>
    </div>

    <section class="work-process-area-two pt-100 pb-70">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-7">
            <div class="row">
              <div class="col-lg-6 col-sm-6">
                <div class="work-process-card-two">
                  <div class="number-title">01.</div>
                  <h3>Discovery</h3>
                  <p>
                    We explore the various ways to provide an efficient solution for your business needs.
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6">
                <div class="work-process-card-two">
                  <div class="number-title">02.</div>
                  <h3>Planning</h3>
                  <p>
                    We organize and plan the development cycle of the solution to be implemented
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6">
                <div class="work-process-card-two">
                  <div class="number-title">03.</div>
                  <h3>Execute</h3>
                  <p>
                    When tasks are been dispatched, execution follows with a goal oriented strategy
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6">
                <div class="work-process-card-two">
                  <div class="number-title">04.</div>
                  <h3>Deliver</h3>
                  <p>
                    Budget and time are the most important factors we take into account for project delivery
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="work-process-right">
              <div class="section-title">
                <span class="sp-color1">Our Working Process</span>
                <h2>How Our Services Will Help You to Grow Your Business</h2>
              </div>
              <router-link
                to="/contacts"
                class="default-btn btn-bg-two border-radius-50 text-center"
                >Contact Us</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="technology-area-three pt-100 pb-70">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="section-title">
              <span class="sp-color1">Technology Index</span>
              <h2>We Deliver Our Best Solution With The Goal of Trusting</h2>
              <p>
                A wide variety of solutions that are cross-platforms and cross-devices.
              </p>
              <router-link
                to="/contacts"
                class="default-btn btn-bg-two border-radius-50 text-center"
                >Contact Us</router-link>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-4 col-6 col-md-4">
                <div class="technology-card technology-card-color2">
                  <i class="flaticon-android"></i>
                  <h3>Android</h3>
                </div>
              </div>
              <div class="col-lg-4 col-6 col-md-4">
                <div class="technology-card technology-card-color2">
                  <i class="flaticon-website"></i>
                  <h3>Apps</h3>
                </div>
              </div>
              <div class="col-lg-4 col-6 col-md-4">
                <div class="technology-card technology-card-color2">
                  <i class="flaticon-apple"></i>
                  <h3>iOS</h3>
                </div>
              </div>
              <div class="col-lg-4 col-6 col-md-4">
                <div class="technology-card technology-card-color2">
                  <i class="flaticon-television"></i>
                  <h3>TV</h3>
                </div>
              </div>
              <div class="col-lg-4 col-6 col-md-4">
                <div class="technology-card technology-card-color2">
                  <i class="flaticon-smartwatch"></i>
                  <h3>Watch</h3>
                </div>
              </div>
              <div class="col-lg-4 col-6 col-md-4">
                <div class="technology-card technology-card-color2">
                  <i class="flaticon-cyber-security"></i>
                  <h3>Security</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="build-area-two pt-100 pb-70">
      <div class="container">
        <div class="section-title text-center">
          <span class="sp-color2">Case Study</span>
          <h2>Let's Change Your IT Infrascture!</h2>
        </div>
        <div class="row pt-45">
          <div class="col-lg-4 col-md-6">
            <div class="build-item">
              <router-link to="/services/system-security">
                <img src="/assets/images/build/build-img4.jpg" alt="Images" />
              </router-link>
              <div class="content">
                <h3><router-link to="/services/system-security">System Security</router-link></h3>
                <ul>
                  <li><router-link to="/services/system-security">Penetration</router-link></li>
                  <li><router-link to="/services/system-security">Defense</router-link></li>
                </ul>
                <router-link to="/services/system-security" class="more-btn"
                  ><i class="bx bx-right-arrow-alt"></i
                ></router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="build-item">
              <router-link to="/services/blockchain">
                <img src="/assets/images/build/build-img5.jpg" alt="Images" />
              </router-link>
              <div class="content">
                <h3><router-link to="/services/blockchain">Cryptocurrency</router-link></h3>
                <ul>
                  <li><router-link to="/services/blockchain">Blockchain</router-link></li>
                </ul>
                <router-link to="/services/blockchain" class="more-btn"
                  ><i class="bx bx-right-arrow-alt"></i
                ></router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
            <div class="build-item">
              <router-link to="/services/data-analysis">
                <img src="/assets/images/build/build-img6.jpg" alt="Images" />
              </router-link>
              <div class="content">
                <h3><router-link to="/services/data-analysis">Data Analysis</router-link></h3>
                <ul>
                  <li><router-link to="/services/data-analysis">Data Collection</router-link></li>
                  <li><router-link to="/services/data-analysis">Statistics</router-link></li>
                </ul>
                <router-link to="/services/data-analysis" class="more-btn"
                  ><i class="bx bx-right-arrow-alt"></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>
<script>
export default {
    name: 'Home4'
}
</script>