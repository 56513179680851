<template>
  <footer class="footer-area footer-bg">
    <div class="container">
      <div class="footer-top pt-100 pb-70">
        <div class="row">
          <div class="col-lg-4 col-sm-6">
            <div class="footer-widget">
              <div class="footer-logo">
                <router-link to="/">
                  <img
                    src="/assets/images/logos/footer-logo.png"
                    alt="Images"
                  />
                </router-link>
              </div>
              <p>
                Using technology to help businesses of all industries solving
                their daily challenges. In Agriculture, transportation, health,
                education, ...sectors the opportunities are endless.
              </p>
              <div class="footer-call-content">
                <h3>Talk to Our Support</h3>
                <span><a href="tel:+85261384110">(+852)61384110</a></span>
                <i class="bx bx-headphone"></i>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-sm-6">
            <div class="footer-widget pl-2">
              <h3>Services</h3>
              <ul class="footer-list">
                <li>
                  <router-link to="/services/it-consulting">
                    <i class="bx bx-chevron-right"></i>
                    IT Consulting
                  </router-link>
                </li>
                <li>
                  <router-link to="/services/data-analysis">
                    <i class="bx bx-chevron-right"></i>
                    Data Analysis
                  </router-link>
                </li>
                <li>
                  <router-link to="/services/machine-learning">
                    <i class="bx bx-chevron-right"></i>
                    Machine Learning
                  </router-link>
                </li>
                <li>
                  <router-link to="/services/system-security">
                    <i class="bx bx-chevron-right"></i>
                    System Security
                  </router-link>
                </li>
                <li>
                  <router-link to="/services/app-development">
                    <i class="bx bx-chevron-right"></i>
                    App Development
                  </router-link>
                </li>
                <li>
                  <router-link to="/services/cloud-computing">
                    <i class="bx bx-chevron-right"></i>
                    Cloud Computing
                  </router-link>
                </li>
                <li>
                  <router-link to="/services/payment-systems">
                    <i class="bx bx-chevron-right"></i>
                    Payment Systems
                  </router-link>
                </li>
                <li>
                  <router-link to="/services/blockchain">
                    <i class="bx bx-chevron-right"></i>
                    Blockchain
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget pl-5">
              <h3>Our Events</h3>
              <ul class="footer-blog">
                <li>
                  <a href="#">
                    <img
                      src="/assets/images/blog/blog-img-footer.jpg"
                      alt="Images"
                    />
                  </a>
                  <div class="content">
                    <h3>
                      <a href="#">Product Idea Solution For New Generation</a>
                    </h3>
                    <span>04 Dec 2020</span>
                  </div>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="/assets/images/blog/blog-img-footer2.jpg"
                      alt="Images"
                    />
                  </a>
                  <div class="content">
                    <h3>
                      <a href="#">New Device Invention for Digital Platform</a>
                    </h3>
                    <span>07 Dec 2020</span>
                  </div>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="/assets/images/blog/blog-img-footer3.jpg"
                      alt="Images"
                    />
                  </a>
                  <div class="content">
                    <h3>
                      <a href="#">Business Strategy Make His Goal Acheive</a>
                    </h3>
                    <span>10 Dec 2020</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget">
              <h3>Newsletter</h3>
              <p>
                Subscribe to our newsletter to have information about new
                services that we provide
              </p>
              <div class="newsletter-area">
                <form
                  class="newsletter-form"
                  data-toggle="validator"
                  method="POST"
                >
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Enter Your Email"
                    name="EMAIL"
                    required
                    autocomplete="off"
                  />
                  <button class="subscribe-btn" type="submit">
                    <i class="bx bx-paper-plane"></i>
                  </button>
                  <div id="validator-newsletter" class="form-result"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copy-right-area">
        <div class="copy-right-text">
          <p>
            &copy;{{ new Date().getFullYear() }} DARA TECH. All rights Reserved
            by
            <a href="https://daragroups.com/" target="_blank">DARA GROUPS</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  created() {
    // var scripts = [
    //   //   "/assets/js/jquery.min.js",
    //   "https://code.jquery.com/jquery-3.6.0.min.js",
    //   "/assets/js/bootstrap.bundle.min.js",
    //   "/assets/js/owl.carousel.min.js",
    //   "/assets/js/jquery.magnific-popup.min.js",
    //   "/assets/js/jquery.nice-select.min.js",
    //   "/assets/js/wow.min.js",
    //   "/assets/js/meanmenu.js",
    //   "/assets/js/jquery.ajaxchimp.min.js",
    //   "/assets/js/form-validator.min.js",
    //   "/assets/js/contact-form-script.js",
    //   "/assets/js/custom.js",
    // ];
    // scripts.forEach((script) => {
    //   let tag = document.createElement("script");
    //   tag.setAttribute("src", script);
    //   document.body.append(tag);
    // });
  },
};
</script>