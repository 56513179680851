<template>
  <div>
    <div class="banner-slider-area">
      <div class="banner-slider owl-carousel owl-theme">
        <div class="banner-item item-bg1">
          <div class="d-table">
            <div class="d-table-cell">
              <div class="container">
                <div class="banner-item-content">
                  <span>Only High Quality Services</span>
                  <h1>Excellent IT Services for Your Success</h1>
                  <p>
                    To achieve the succes that your organization deserves, you
                    need more than just the standards. Because we believe that
                    behind every success, there is a high quality service.
                  </p>
                  <div class="banner-btn">
                    <router-link
                      to="/services"
                      class="default-btn btn-bg-two border-radius-50"
                      >Learn More <i class="bx bx-chevron-right"></i
                    ></router-link>
                    <router-link
                      to="/contacts"
                      class="default-btn btn-bg-one border-radius-50 ml-20"
                      >Contact Us <i class="bx bx-chevron-right"></i
                    ></router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="banner-item item-bg2">
          <div class="d-table">
            <div class="d-table-cell">
              <div class="container">
                <div class="banner-item-content">
                  <span>Only High Quality Services</span>
                  <h1>We Provide Best IT Services for Your Need</h1>
                  <p>
                    We associate together expertise and quality to render each
                    and every service. Because we also believe that whenever
                    best is possible, quality is not enough
                  </p>
                  <div class="banner-btn">
                    <router-link
                      to="/services"
                      class="default-btn btn-bg-two border-radius-50"
                      >Learn More <i class="bx bx-chevron-right"></i
                    ></router-link>
                    <router-link
                      to="/contacts"
                      class="default-btn btn-bg-one border-radius-50 ml-20"
                      >Contact Us <i class="bx bx-chevron-right"></i
                    ></router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="banner-item item-bg3">
          <div class="d-table">
            <div class="d-table-cell">
              <div class="container">
                <div class="banner-item-content">
                  <span>Only High Quality Services</span>
                  <h1>Digital IT Service With Excellent Quality</h1>
                  <p>
                    Digitalization is the trend so we understand the type of IT
                    Service you need. Because and surely for each and every one
                    of your need we offer the corresponding service.
                  </p>
                  <div class="banner-btn">
                    <router-link
                      to="/services"
                      class="default-btn btn-bg-two border-radius-50"
                      >Learn More <i class="bx bx-chevron-right"></i
                    ></router-link>
                    <router-link
                      to="/contacts"
                      class="default-btn btn-bg-one border-radius-50 ml-20"
                      >Contact Us <i class="bx bx-chevron-right"></i
                    ></router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="about-area ptb-100">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-6">
            <div class="about-content mr-20">
              <div class="section-title">
                <span class="sp-color2">2 Years of Experience</span>
                <h2>Right Partner For IT Services</h2>
                <p>
                  Long term or short term IT Services partnership that changes
                  the game and take your business to the next level.
                </p>
              </div>
              <div class="row">
                <div class="col-lg-6 col-6">
                  <div class="about-content-card">
                    <i class="flaticon-practice"></i>
                    <h3>Experience</h3>
                    <p>That's what makes innovative the softwares we build.</p>
                  </div>
                </div>
                <div class="col-lg-6 col-6">
                  <div class="about-content-card">
                    <i class="flaticon-help"></i>
                    <h3>Quick Support</h3>
                    <p>Our support team is available 24/7 to assist you.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-img">
              <img
                src="/assets/images/about/about-img1.jpg"
                alt="About Images"
              />
              <div class="sub-content">
                <img
                  src="/assets/images/about/about-img2.jpg"
                  alt="About Images"
                />
                <div class="content">
                  <h3>4.5k</h3>
                  <span>Satisfied Clients</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="services-area pt-100 pb-70">
      <div class="container">
        <div class="section-title text-center">
          <span class="sp-color1">Our Services</span>
          <h2>We Provide a Wide Variety of IT Services</h2>
          <p class="margin-auto">
            We go beyond the simple thoughts of what can be done and provide
            what should be done. The number of our IT services is by no means
            limited to only what you read.
          </p>
        </div>
        <div class="row pt-45">
          <div class="col-lg-3 col-sm-6">
            <div class="services-card">
              <i class="flaticon-consultant"></i>
              <h3>
                <router-link to="/services/it-consulting"
                  >IT Consulting</router-link
                >
              </h3>
              <p>
                We direct your choice and help you implementing the appropriate
                IT infrastructure needed for your business
              </p>
              <router-link to="/services/it-consulting" class="learn-btn"
                >Learn More <i class="bx bx-chevron-right"></i
              ></router-link>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="services-card">
              <i class="flaticon-consulting"></i>
              <h3>
                <router-link to="/services/cloud-computing"
                  >Cloud Computing</router-link
                >
              </h3>
              <p>
                We help you saving maintenance cost and preventing your IT
                infrastructure from natural disasters.
              </p>
              <router-link to="/services/cloud-computing" class="learn-btn"
                >Learn More <i class="bx bx-chevron-right"></i
              ></router-link>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="services-card">
              <i class="flaticon-web-development"></i>
              <h3>
                <router-link to="/services/app-development"
                  >App Development</router-link
                >
              </h3>
              <p>
                Either desktop, web or mobile application, we can transform any
                innovative idea into a reliable software system.
              </p>
              <router-link to="/services/app-development" class="learn-btn"
                >Learn More <i class="bx bx-chevron-right"></i
              ></router-link>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="services-card">
              <i class="flaticon-cyber-security"></i>
              <h3>
                <router-link to="/services/system-security"
                  >System Security</router-link
                >
              </h3>
              <p>
                We help you securing your IT systems against malicious users.
                Penetration and infiltration testing are our basics.
              </p>
              <router-link to="/services/system-security" class="learn-btn"
                >Learn More <i class="bx bx-chevron-right"></i
              ></router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="service-shape">
        <img src="/assets/images/shape/service-shape1.png" alt="Images" />
      </div>
    </section>

    <section class="work-process-area pt-100 pb-70">
      <div class="container">
        <div class="section-title text-center">
          <span class="sp-color2">Our Working Process</span>
          <h2>How Our Services Will Help You to Grow Your Business</h2>
        </div>
        <div class="row pt-45">
          <div class="col-lg-3 col-sm-6">
            <div class="work-process-card">
              <i class="flaticon-project"></i>
              <h3>Discovery</h3>
              <p>
                We explore the various ways to provide an efficient solution for
                your business needs.
              </p>
              <div class="number">01</div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="work-process-card">
              <i class="flaticon-chip"></i>
              <h3>Planning</h3>
              <p>
                We organize and plan the development cycle of the solution to be
                implemented
              </p>
              <div class="number">02</div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="work-process-card">
              <i class="flaticon-effective"></i>
              <h3>Execute</h3>
              <p>
                When tasks are been dispatched, execution follows with a goal
                oriented strategy
              </p>
              <div class="number">03</div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="work-process-card">
              <i class="flaticon-bullhorn"></i>
              <h3>Deliver</h3>
              <p>
                Budget and time are the most important factors we take into
                account for project delivery
              </p>
              <div class="number">04</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="build-area pt-100 pb-70">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-8">
            <div class="build-content">
              <div class="section-title">
                <span>We Carry More Than Just Good Coding Skills</span>
                <h2>Let's Build Your Software Project!</h2>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="build-btn-area">
              <router-link
                to="/contacts"
                class="default-btn btn-bg-two border-radius-50"
                >Contact Us <i class="bx bx-chevron-right"></i
              ></router-link>
            </div>
          </div>
        </div>
        <div class="build-play-img pt-45">
          <img src="/assets/images/build-img.jpg" alt="Images" />
          <div class="play-area-content">
            <span>Watch Our Intro Video</span>
            <h2>Perfect Solution for IT Services!</h2>
          </div>
          <div class="play-area">
            <a
              href="https://www.youtube.com/watch?v=tUP5S4YdEJo"
              class="play-on popup-btn"
              ><i class="bx bx-play"></i
            ></a>
          </div>
        </div>
      </div>
    </div>

    <div class="counter-area pb-70">
      <div class="container">
        <div class="section-title text-center">
          <span class="sp-color2">Numbers Are Talking</span>
          <h2>Let’s Check Our Business Growth and Success Story</h2>
          <p>
            We are not yet satsified with our achievement. We are ready to do
            more.
          </p>
        </div>
        <div class="row pt-45">
          <div class="col-lg-3 col-6 col-md-3">
            <div class="counter-another-content">
              <i class="flaticon-web-development"></i>
              <h3>4205+</h3>
              <span>Lines of Code</span>
            </div>
          </div>
          <div class="col-lg-3 col-6 col-md-3">
            <div class="counter-another-content">
              <i class="flaticon-consulting-1"></i>
              <h3>5+</h3>
              <span>IT Consulting</span>
            </div>
          </div>
          <div class="col-lg-3 col-6 col-md-3">
            <div class="counter-another-content">
              <i class="flaticon-startup"></i>
              <h3>2+</h3>
              <span>Fully Launched</span>
            </div>
          </div>
          <div class="col-lg-3 col-6 col-md-3">
            <div class="counter-another-content">
              <i class="flaticon-tick"></i>
              <h3>2+</h3>
              <span>Project Completed</span>
            </div>
          </div>
        </div>
      </div>
      <div class="counter-shape">
        <div class="shape1">
          <img src="/assets/images/shape/shape1.png" alt="Images" />
        </div>
        <div class="shape2">
          <img src="/assets/images/shape/shape2.png" alt="Images" />
        </div>
      </div>
    </div>

    <div class="call-us-area">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="call-contact">
              <h3>Call Us 24/7</h3>
              <a href="tel:(+852)61384110" class="call-btn">+85261384110</a>
              <p>
                For enquiries or technical and innovative solutions for your
                business, our team is always available for you.
              </p>
              <router-link
                to="/contacts"
                class="default-btn btn-bg-two border-radius-5"
                >Contact Us</router-link
              >
            </div>
          </div>
          <div class="col-lg-6">
            <div class="call-us-img">
              <img src="/assets/images/call-us/call-us-img1.png" alt="Images" />
              <div class="call-shape">
                <div class="shape1">
                  <img
                    src="/assets/images/call-us/call-shap2.png"
                    alt="Images"
                  />
                </div>
                <div class="shape2 shape2-rs">
                  <img
                    src="/assets/images/call-us/call-shap3.png"
                    alt="Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="case-study-area pt-100 pb-70">
      <div class="container-fluid p-0">
        <div class="section-title text-center">
          <span class="sp-color2">You think you have an idea?</span>
          <h2>Yeah! We also have a solution for you.</h2>
        </div>
      </div>
    </div>

    <section class="technology-area pt-100 pb-70">
      <div class="container">
        <div class="section-title text-center">
          <span class="sp-color1">Technology Index</span>
          <h2>We Deliver Our Best Solution With The Goal of Trusting</h2>
        </div>
        <div class="row pt-45">
          <div class="col-lg-2 col-6">
            <div class="technology-card">
              <i class="flaticon-android"></i>
              <h3>Android</h3>
            </div>
          </div>
          <div class="col-lg-2 col-6">
            <div class="technology-card">
              <i class="flaticon-website"></i>
              <h3>Apps</h3>
            </div>
          </div>
          <div class="col-lg-2 col-6">
            <div class="technology-card">
              <i class="flaticon-apple"></i>
              <h3>iOS</h3>
            </div>
          </div>
          <div class="col-lg-2 col-6">
            <div class="technology-card">
              <i class="flaticon-television"></i>
              <h3>TV</h3>
            </div>
          </div>
          <div class="col-lg-2 col-6">
            <div class="technology-card">
              <i class="flaticon-smartwatch"></i>
              <h3>Watch</h3>
            </div>
          </div>
          <div class="col-lg-2 col-6">
            <div class="technology-card">
              <i class="flaticon-cyber-security"></i>
              <h3>Security</h3>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="brand-area ptb-100">
      <div class="container">
        <div class="brand-slider owl-carousel owl-theme">
          <div class="brand-item">
            <img src="/assets/images/brand-logo/brand-logo1.png" alt="Images" />
          </div>
          <div class="brand-item">
            <img src="/assets/images/brand-logo/brand-logo2.png" alt="Images" />
          </div>
          <div class="brand-item">
            <img src="/assets/images/brand-logo/brand-logo3.png" alt="Images" />
          </div>
          <div class="brand-item">
            <img src="/assets/images/brand-logo/brand-logo4.png" alt="Images" />
          </div>
          <div class="brand-item">
            <img src="/assets/images/brand-logo/brand-logo5.png" alt="Images" />
          </div>
          <div class="brand-item">
            <img src="/assets/images/brand-logo/brand-logo3.png" alt="Images" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
