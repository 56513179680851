<template>
    <div class="col-lg-4">
        <div class="side-bar-area">
            <div class="side-bar-widget">
            <h3 class="title">Our Services</h3>
                <div class="side-bar-categories">
                    <ul>
                        <li>
                            <div class="line-circle"></div>
                            <router-link to="/services/it-consulting">
                            IT Consulting
                            </router-link>
                        </li>
                        <li>
                            <div class="line-circle"></div>
                            <router-link to="/services/cloud-computing">
                            Cloud Computing
                            </router-link>
                        </li>
                        <li>
                            <div class="line-circle"></div>
                            <router-link to="/services/app-development">
                            App Development
                            </router-link>
                        </li>
                        <li>
                            <div class="line-circle"></div>
                            <router-link to="/services/blockchain">
                            Blockchain
                            </router-link>
                        </li>
                        <li>
                            <div class="line-circle"></div>
                            <router-link to="/services/data-analysis">
                            Data Analysis
                            </router-link>
                        </li>
                        <li>
                            <div class="line-circle"></div>
                            <router-link to="/services/machine-learning">
                                Machine Learning
                            </router-link>
                        </li>
                        <li>
                            <div class="line-circle"></div>
                            <router-link to="/services/system-security">
                            System Security
                            </router-link>
                        </li>
                        <li>
                            <div class="line-circle"></div>
                            <router-link to="/services/payment-systems">
                                Payment Systems
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="side-bar-widget">
                <h3 class="title">Tag Cloud</h3>
                <ul class="side-bar-widget-tag">
                    <li>
                        <router-link to="/services/app-development">Android</router-link>
                    </li>
                    <li>
                        <router-link to="/services/app-development">iOS</router-link>
                    </li>
                    <li>
                        <router-link to="/services/system-security">Security</router-link>
                    </li>
                    <li>
                        <router-link to="/services/app-development">Software</router-link>
                    </li>
                    <li>
                        <router-link to="/services/blockchain">Blockchain</router-link>
                    </li>
                    <li>
                        <router-link to="/services/app-development">Mobile</router-link>
                    </li>
                    <li>
                        <router-link to="/services/payment-systems">Payment</router-link>
                    </li>
                    <li>
                        <router-link to="/services/cloud-computing">Cloud</router-link>
                    </li>
                    <li>
                        <router-link to="/services/data-analysis">Data</router-link>
                    </li>
                    <li>
                        <router-link to="/services/it-consulting">Consulting</router-link>
                    </li>
                    <li>
                        <router-link to="/services/machine-learning">AI</router-link>
                    </li>
                </ul>
            </div>
            <div class="side-bar-widget">
                <h3 class="title">Gallery</h3>
                <ul class="blog-gallery">
                    <li>
                        <a href="#">
                            <img
                            src="/assets/images/blog/blog-small-img1.jpg"
                            alt="image"
                            />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img
                            src="/assets/images/blog/blog-small-img2.jpg"
                            alt="image"
                            />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img
                            src="/assets/images/blog/blog-small-img3.jpg"
                            alt="image"
                            />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img
                            src="/assets/images/blog/blog-small-img4.jpg"
                            alt="image"
                            />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img
                            src="/assets/images/blog/blog-small-img5.jpg"
                            alt="image"
                            />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img
                            src="/assets/images/blog/blog-small-img6.jpg"
                            alt="image"
                            />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ServiceSidebar'
}
</script>