<template>
     <div>
       <div class="inner-banner">
      <div class="container">
        <div class="inner-title text-center">
          <h3>App Development</h3>
          <ul>
            <li>
              <router-link to="/services">Services</router-link>
            </li>
            <li>
              <i class="bx bx-chevrons-right"></i>
            </li>
            <li>App Development</li>
          </ul>
        </div>
      </div>
      <div class="inner-shape">
        <img src="/assets/images/shape/inner-shape.png" alt="Images" />
      </div>
    </div>

    <div class="service-details-area pt-100 pb-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="service-article">
              <div class="service-article-img">
                <img
                  src="/assets/images/services/service-details.jpg"
                  alt="Images"
                />
              </div>
              <div class="service-article-content">
                <h2>App Development</h2>
                <p style="text-align:justify">
                  <b>Mobile app </b>development  is the act or process by which a mobile app is developed for 
                  mobile devices, such as personal digital assistants, enterprise digital assistants or 
                  mobile phones. These software applications are designed to run on mobile devices, such 
                  as a smartphone or tablet computer. These applications can be pre-installed on phones 
                  during manufacturing platforms, or delivered as web applications using server-side or 
                  client-side processing (e.g., JavaScript) to provide an "application-like" experience 
                  within a Web browser. 
                </p>
                <p style="text-align:justify">
                  <b>A web application (or web app)</b> is application software that runs on a web server, 
                    unlike computer-based software programs that are run locally on the operating system (OS) 
                    of the device. Web applications are accessed by the user through a web browser with an active 
                    network connection. These applications are programmed using a client–server modeled 
                    structure—the user ("client") is provided services through an off-site server that is 
                    hosted by a third-party.
                </p>
                <p style="text-align:justify">
                  A desktop application is a software program that can be run on a standalone computer 
                  to perform a specific task by an end-user. Some desktop applications such as word 
                  editor and media player allow you to perform different tasks while other such as 
                  gaming apps are developed purely for entertainment. When you purchase a computer 
                  or a laptop, there is a set of apps that are already installed on your desktop. 
                  You can also download and install different desktop applications directly from 
                  the Internet or purchased from software vendors.
                </p>
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <ul class="service-article-list service-article-rs">
                      <li>
                        <i class="bx bxs-check-circle"></i>Airport Software 
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Ticketing Software
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>School Management Software
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Inventory Management Software
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Utility Software
                      </li>
                    </ul>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <ul class="service-article-list">
                      <li>
                        <i class="bx bxs-check-circle"></i>Hosptial Management Software
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Financial Software
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Warehouse Management Software
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Productivity Software
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Multimedia Software
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ServiceSideBar />
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import ServiceSideBar from '@/components/ServiceSidebar.vue'
export default {
    name: 'AppDevelopmentDetails',
    components: {
      ServiceSideBar
    }
}
</script>