<template>
    <div>
      <div class="banner-five-area">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="banner-five-content">
              <span>TECHNOLOGY & IT SOLUTIONS</span>
              <h1>
                IT Solutions , <b>Digital</b> Technology & Services All Around
                the World
              </h1>
              <p>
                We globally serve our customers digital technology and services that satisfy their business' needs
              </p>
              <router-link
                to="/contacts"
                class="default-btn btn-bg-two border-radius-50"
              >Contact Us</router-link>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="banner-five-img">
              <img src="/assets/images/home-five-img.png" alt="Images" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="work-process-area pt-100 pb-70">
      <div class="container">
        <div class="section-title text-center">
          <span class="sp-color2">Our Working Process</span>
          <h2>How Our Services Will Help You to Grow Your Business</h2>
        </div>
        <div class="row pt-45">
          <div class="col-lg-3 col-sm-6">
            <div class="work-process-card-three">
              <div class="number-title">01.</div>
              <h3>Discovery</h3>
              <p>
                We explore the various ways to provide an efficient solution for your business needs.
              </p>
              <i class="flaticon-project"></i>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="work-process-card-three">
              <div class="number-title">02.</div>
              <h3>Planning</h3>
              <p>
                We organize and plan the development cycle of the solution to be implemented
              </p>
              <i class="flaticon-chip"></i>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="work-process-card-three">
              <div class="number-title">03.</div>
              <h3>Execute</h3>
              <p>
                When tasks are been dispatched, execution follows with a goal oriented strategy
              </p>
              <i class="flaticon-effective"></i>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="work-process-card-three">
              <div class="number-title">04.</div>
              <h3>Deliver</h3>
              <p>
                Budget and time are the most important factors we take into account for project delivery
              </p>
              <i class="flaticon-bullhorn"></i>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="about-area about-bg2 pt-100 pb-70">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="about-img-4">
              <img
                src="/assets/images/about/about-img4.png"
                alt="About Images"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-content-3 ml-20">
              <div class="section-title">
                <span class="sp-color2">About Us</span>
                <h2>Right Partner for Software Innovation</h2>
                <p>
                  From Saas(Software as a service) to Paas(Platform as a service) and any other innovative software,
                we have all the resources to deliver on budget and on time.
                </p>
              </div>
              <h3>
                We Have 1+ Year Of Experience. We Offer IT Solutions , Digital
                Technology Service
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="services-area-four pt-100 pb-70">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4">
            <div class="services-left">
              <div class="section-title">
                <span class="sp-color2">Our Services</span>
                <h2>Searching for a Solution! We Provide Truly IT Solutions</h2>
                <p class="margin-auto">
                  Because we want your business to standout in the thoughest competition, so 
                  we take the responsibility to provide you with truely IT solutions.
                </p>
              </div>
              <router-link
                to="/services"
                class="default-btn btn-bg-two border-radius-50 text-center"
                >View All Services</router-link>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="row">
              <div class="col-lg-6 col-sm-6">
                <div class="services-card services-card-color-bg">
                  <i class="flaticon-consultant"></i>
                  <h3><router-link to="/services/it-consulting">IT Consulting</router-link></h3>
                  <p>
                    We direct your choice and help you implementing the appropriate IT infrastructure 
                    needed for your business
                  </p>
                  <router-link to="/services/it-consulting" class="learn-btn"
                    >Learn More <i class="bx bx-chevron-right"></i
                  ></router-link>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6">
                <div class="services-card services-card-color-bg">
                  <i class="flaticon-consulting"></i>
                  <h3><router-link to="/services/cloud-computing">Cloud Computing</router-link></h3>
                  <p>
                    We help you saving maintenance cost and preventing your IT infrastructure from 
                    natural disasters.
                  </p>
                  <router-link to="/services/cloud-computing" class="learn-btn"
                    >Learn More <i class="bx bx-chevron-right"></i
                  ></router-link>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6">
                <div class="services-card services-card-color-bg">
                  <i class="flaticon-web-development"></i>
                  <h3><router-link to="/services/app-development">App Development</router-link></h3>
                  <p>
                    Either desktop, web or mobile application, we can transform any innovative idea 
                    into a reliable software system.
                  </p>
                  <router-link to="/services/app-development" class="learn-btn"
                    >Learn More <i class="bx bx-chevron-right"></i
                  ></router-link>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6">
                <div class="services-card services-card-color-bg">
                  <i class="flaticon-cyber-security"></i>
                  <h3><router-link to="/services/system-security">System Security</router-link></h3>
                  <p>
                    We help you securing your IT systems against malicious users. Penetration and 
                    infiltration testing are our basics.
                  </p>
                  <router-link to="/services/system-security" class="learn-btn"
                    >Learn More <i class="bx bx-chevron-right"></i
                  ></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="counter-area pt-100 pb-70">
      <div class="container">
        <div class="section-title text-center">
          <span class="sp-color2">Numbers Are Talking</span>
          <h2>Let’s Check Our Business Growth and Success Story</h2>
          <p>
            We are not yet satsified with our achievement. We are ready to do more.
          </p>
        </div>
        <div class="row pt-45">
          <div class="col-lg-3 col-6 col-md-3">
            <div class="counter-another-content">
              <i class="flaticon-web-development"></i>
              <h3>4205+</h3>
              <span>Lines of Code</span>
            </div>
          </div>
          <div class="col-lg-3 col-6 col-md-3">
            <div class="counter-another-content">
              <i class="flaticon-consulting-1"></i>
              <h3>5+</h3>
              <span>IT Consulting</span>
            </div>
          </div>
          <div class="col-lg-3 col-6 col-md-3">
            <div class="counter-another-content">
              <i class="flaticon-startup"></i>
              <h3>2+</h3>
              <span>Fully Launched</span>
            </div>
          </div>
          <div class="col-lg-3 col-6 col-md-3">
            <div class="counter-another-content">
              <i class="flaticon-tick"></i>
              <h3>2+</h3>
              <span>Project Completed</span>
            </div>
          </div>
        </div>
      </div>
      <div class="counter-shape">
        <div class="shape1">
          <img src="/assets/images/shape/shape1.png" alt="Images" />
        </div>
        <div class="shape2">
          <img src="/assets/images/shape/shape2.png" alt="Images" />
        </div>
      </div>
    </div>

    <div class="case-study-area-two pt-100 pb-70">
      <div class="container">
        <div class="section-title text-center">
          <span class="sp-color2">Case Study</span>
          <h2>Introduce Our Projects and Check Recent Work</h2>
        </div>
        <div class="row justify-content-center pt-45">
          <div class="col-lg-4 col-sm-6">
            <div class="case-study-item">
              <router-link to="/services/system-security">
                <img
                  src="/assets/images/case-study/case-study1.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <h3><router-link to="/services/system-security">System Security</router-link></h3>
                <ul>
                  <li><router-link to="/services/system-security">Penetration</router-link></li>
                  <li><router-link to="/services/system-security">Defense</router-link></li>
                </ul>
                <router-link to="/services/system-security" class="more-btn"
                  ><i class="bx bx-right-arrow-alt"></i
                ></router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="case-study-item">
              <router-link to="/services/blockchain">
                <img
                  src="/assets/images/case-study/case-study2.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <h3><router-link to="/services/blockchain">Cryptocurrency</router-link></h3>
                <ul>
                  <li><router-link to="/services/blockchain">Blockchain</router-link></li>
                </ul>
                <router-link to="/services/blockchain" class="more-btn"
                  ><i class="bx bx-right-arrow-alt"></i
                ></router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="case-study-item">
              <router-link to="/services/payment-systems">
                <img
                  src="/assets/images/case-study/case-study3.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <h3><router-link to="/services/payment-systems">Payment Systems</router-link></h3>
                <ul>
                  <li><router-link to="/services/payment-systems">Remittance</router-link></li>
                  <li><router-link to="/services/payment-systems">Point of Sale(POS)</router-link></li>
                </ul>
                <router-link to="/services/payment-systems" class="more-btn"
                  ><i class="bx bx-right-arrow-alt"></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="technology-area-four pt-100 pb-70">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="technology-right">
              <div class="section-title">
                <span class="sp-color2">Technology Index</span>
                <h2>We Deliver Our Best Solution With The Goal of Trusting</h2>
                <p>
                  A wide variety of solutions that are cross-platforms and cross-devices.
                </p>
              </div>
              <div class="row">
                <div class="col-lg-3 col-6 col-sm-3">
                  <div class="technology-item">
                    <i class="flaticon-android"></i>
                    <h3>Android</h3>
                  </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-3">
                  <div class="technology-item">
                    <i class="flaticon-website"></i>
                    <h3>Apps</h3>
                  </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-3">
                  <div class="technology-item">
                    <i class="flaticon-apple"></i>
                    <h3>iOS</h3>
                  </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-3">
                  <div class="technology-item">
                    <i class="flaticon-television"></i>
                    <h3>TV</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="technology-play-area">
              <a
                href="https://www.youtube.com/watch?v=tUP5S4YdEJo"
                class="play-btn popup-btn"
              >
                <i class="bx bx-play"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
</template>
<script>
export default {
    name: 'Home5'
}
</script>