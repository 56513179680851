<template>
     <div>
       <div class="inner-banner">
      <div class="container">
        <div class="inner-title text-center">
          <h3>IT Consulting</h3>
          <ul>
            <li>
              <router-link to="/services">Services</router-link>
            </li>
            <li>
              <i class="bx bx-chevrons-right"></i>
            </li>
            <li>IT Consulting</li>
          </ul>
        </div>
      </div>
      <div class="inner-shape">
        <img src="/assets/images/shape/inner-shape.png" alt="Images" />
      </div>
    </div>

    <div class="service-details-area pt-100 pb-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="service-article">
              <div class="service-article-img">
                <img
                  src="/assets/images/services/service-details.jpg"
                  alt="Images"
                />
              </div>
              <div class="service-article-content">
                <h2>IT Consulting</h2>
                <p style="text-align:justify">
                  In management, information technology consulting (also called IT consulting, 
                  computer consultancy, business and technology services, computing consultancy, 
                  technology consulting, and IT advisory) is a field of activity which focuses on advising 
                  organizations on how best to use information technology (IT) in achieving their business 
                  objectives.
                </p>
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <ul class="service-article-list service-article-rs">
                      <li>
                        <i class="bx bxs-check-circle"></i>Gaining external, objective advice and recommendations
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Gaining access to the consultants' specialized expertise
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Temporary helping during a one-time project where the hiring of a permanent employee(s) is not required or necessary
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>To outsource all or part of the IT services from a specific company.
                      </li>
                    </ul>
                  </div>
                </div>
                <p style="text-align:justify">
                  Once a business owner defined the needs to take a business to the next level, a 
                  decision maker will define a scope, cost and a time frame of the project.[1] The role of 
                  the IT consultancy company is to support and nurture the company from the very beginning of 
                  the project until the end, and deliver the project not only in the scope, time and cost but 
                  also with complete customer satisfaction.
                </p>
              </div>
              
            </div>
          </div>
          <ServiceSidebar />
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import ServiceSidebar from '@/components/ServiceSidebar.vue'
export default {
    name: 'ITConsultingDetails',
    components: {
      ServiceSidebar
    }
}
</script>