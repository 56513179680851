<template>
     <div>
       <div class="inner-banner">
      <div class="container">
        <div class="inner-title text-center">
          <h3>Blockchain</h3>
          <ul>
            <li>
              <router-link to="/services">Services</router-link>
            </li>
            <li>
              <i class="bx bx-chevrons-right"></i>
            </li>
            <li>Blockchain</li>
          </ul>
        </div>
      </div>
      <div class="inner-shape">
        <img src="/assets/images/shape/inner-shape.png" alt="Images" />
      </div>
    </div>

    <div class="service-details-area pt-100 pb-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="service-article">
              <div class="service-article-img">
                <img
                  src="/assets/images/services/service-details.jpg"
                  alt="Images"
                />
              </div>
              <div class="service-article-content">
                <h2>Blockchain</h2>
                <p style="text-align:justify">
                  A blockchain is a growing list of records, called blocks, that are linked together using 
                  cryptography. It's also described as a "trustless and fully decentralized peer-to-peer 
                  immutable data storage" that is spread over a network of participants often referred to as 
                  nodes. Each block contains a cryptographic hash of the previous block, a timestamp, and 
                  transaction data (generally represented as a Merkle tree). 
                </p>
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <ul class="service-article-list service-article-rs">
                      <li>
                        <i class="bx bxs-check-circle"></i> Distributed ledger for cryptocurrencies such as bitcoin;
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i> Data decentralization
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i> Guarding against bad actors is not required and no access control is needed
                      </li>
                    </ul>
                  </div>
                  
                </div>
                <p style="text-align:justify">
                  Blockchains are typically managed by a peer-to-peer network for use as a publicly distributed 
                  ledger, where nodes collectively adhere to a protocol to communicate and validate new blocks. 
                  Although blockchain records are not unalterable as forks are possible, blockchains may be 
                  considered secure by design and exemplify a distributed computing system with high 
                  Byzantine fault tolerance.
                </p>
              </div>
              <div class="service-article-another">
                <h2>What Benefit You Will Get</h2>
                <p style="text-align:justify">
                  Blockchain technology can be used to create a permanent, public, transparent ledger
                  system for compiling data on sales, tracking digital use and payments to content
                  creators, such as wireless users[121] or musicians.[122] The Gartner 2019 CIO Survey
                  reported 2% of higher education respondents had launched blockchain projects and
                  another 18% were planning academic projects in the next 24 months.[123] In 2017,
                  IBM partnered with ASCAP and PRS for Music to adopt blockchain technology in music
                  distribution.[124] Imogen Heap's Mycelia service has also been proposed as
                  blockchain-based alternative "that gives artists more control over how their
                  songs and associated data circulate among fans and other musicians."
                </p>
                <div class="row">
                  <div class="col-lg-6 col-sm-6">
                    <div class="service-article-another-img">
                      <img
                        src="/assets/images/services/service-details-img1.jpg"
                        alt="Images"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="service-article-another-img">
                      <img
                        src="/assets/images/services/service-details-img2.jpg"
                        alt="Images"
                      />
                    </div>
                  </div>
                </div>
                <p style="text-align:justify">
                  New distribution methods are available for the insurance industry such as 
                  peer-to-peer insurance, parametric insurance and microinsurance following the 
                  adoption of blockchain.The sharing economy and IoT are also set to 
                  benefit from blockchains because they involve many collaborating peers. 
                  The use of blockchain in libraries is being studied with a grant from the U.S. 
                  Institute of Museum and Library Services.
                  Other designs include:
                  Hyperledger is a cross-industry collaborative effort from the Linux Foundation to 
                  support blockchain-based distributed ledgers, with projects under this initiative 
                  including Hyperledger Burrow (by Monax) and Hyperledger Fabric (spearheaded by IBM).
                  Quorum – a permissionable private blockchain by JPMorgan Chase with private storage, 
                  used for contract applications.
                  Tezos, decentralized voting.
                  Proof of Existence is an online service that verifies the existence of computer files 
                  as of a specific time.
                </p>
              </div>
              <div class="service-work-process">
                <h2>Advantages of Blockchain</h2>
                  <p style="text-align:justify">
                    Most cryptocurrencies use blockchain technology to record transactions. For example, 
                    the bitcoin network and Ethereum network are both based on blockchain. 
                    On 8 May 2018 Facebook confirmed that it would open a new blockchain group[70] 
                    which would be headed by David Marcus, who previously was in charge of Messenger. 
                    Facebook's planned cryptocurrency platform, Libra (now known as Diem), was formally 
                    announced on June 18, 2019.
                    The criminal enterprise Silk Road, which operated on Tor, utilized cryptocurrency for 
                    payments, some of which the US federal government has seized through research on the 
                    blockchain and forfeiture.

                    Governments have mixed policies on the legality of their citizens or banks owning 
                    cryptocurrencies. China implements blockchain technology in several industries 
                    including a national digital currency which launched in 2020. In order to 
                    strengthen their respective currencies, Western governments including the European 
                    Union and the United States have initiated similar projects.
                  </p>
              </div>
            </div>
          </div>
          <ServiceSidebar />
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import ServiceSidebar from '@/components/ServiceSidebar.vue'
export default {
    name: 'BlockchainDetails',
    components: {
      ServiceSidebar
    }
}
</script>