<template>
    <div>
      <div class="banner-area-two">
      <div class="container-fluid">
        <div class="container-max">
          <div class="row align-items-center">
            <div class="col-lg-5">
              <div class="banner-content">
                <h1>Digital IT Service With Excellent Quality</h1>
                <p>
                  Living in the era of digitalization, we know very well the digital IT Service needed for your business.
                </p>
                <div class="banner-btn">
                  <router-link
                    to="/services"
                    class="default-btn btn-bg-two border-radius-50"
                    >Learn More <i class="bx bx-chevron-right"></i
                  ></router-link>
                  <router-link
                    to="/contacts"
                    class="default-btn btn-bg-one border-radius-50 ml-20"
                    >Contact Us <i class="bx bx-chevron-right"></i
                  ></router-link>
                </div>
              </div>
            </div>
            <div class="col-lg-7">
              <div class="banner-img">
                <img
                  src="/assets/images/home-three/home-three-img.png"
                  alt="Images"
                />
                <div class="banner-img-shape">
                  <img
                    src="/assets/images/home-three/home-three-shape.png"
                    alt="Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="banner-sub-slider owl-carousel owl-theme">
          <div class="banner-sub-item">
            <img
              src="/assets/images/home-three/home-three-sub1.jpg"
              alt="Images"
            />
            <div class="content">
              <h3>3.7k</h3>
              <span>Satisfied Clients</span>
            </div>
          </div>
          <div class="banner-sub-item">
            <img
              src="/assets/images/home-three/home-three-sub2.jpg"
              alt="Images"
            />
            <div class="content">
              <h3>45+</h3>
              <span>Country Worldwide</span>
            </div>
          </div>
          <div class="banner-sub-item">
            <img
              src="/assets/images/home-three/home-three-sub3.jpg"
              alt="Images"
            />
            <div class="content">
              <h3>6.5+</h3>
              <span>Project Completed</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="about-area about-bg pt-100 pb-70">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="about-img-2">
              <img
                src="/assets/images/about/about-img3.jpg"
                alt="About Images"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-content-2 ml-20">
              <div class="section-title">
                <span class="sp-color1">About Us</span>
                <h2>Right Partner for Software Innovation</h2>
                <p>
                  From Saas(Software as a service) to Paas(Platform as a service) and any other innovative software,
                  we have all the resources to deliver on budget and on time.
                </p>
              </div>
              <div class="row">
                <div class="col-lg-6 col-6">
                  <div class="about-card">
                    <div class="content">
                      <i class="flaticon-practice"></i>
                      <h3>Experience</h3>
                    </div>
                    <p>
                      That's what makes innovative the softwares we build.
                    </p>
                  </div>
                </div>
                <div class="col-lg-6 col-6">
                  <div class="about-card">
                    <div class="content">
                      <i class="flaticon-help"></i>
                      <h3>Quick Support</h3>
                    </div>
                    <p>
                      Our support team is available 24/7 to assist you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="security-area pt-100 pb-70">
      <div class="container">
        <div class="section-title text-center">
          <span class="sp-color2">IT Security & Computing</span>
          <h2>
            Searching for a Solution! We Provide Truly Prominent IT Solutions
          </h2>
        </div>
        <div class="row pt-45">
          <div class="col-lg-4 col-sm-6">
            <div class="security-card">
              <i class="flaticon-cyber-security"></i>
              <h3><router-link to="/services/system-security">System Security</router-link></h3>
              <p>
                We help you securing your IT systems against malicious users. Penetration and 
                infiltration testing are our basics.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="security-card">
              <i class="flaticon-consultant"></i>
              <h3><router-link to="/services/payment-systems">Payment Systems</router-link></h3>
              <p>
                The world is going cashless. So, we provide to businesses and financial institutions
                the systems to send and receive payments.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="security-card">
              <i class="flaticon-effective"></i>
              <h3><router-link to="/services/data-analysis">Data Analysis</router-link></h3>
              <p>
                To improve the product or service accpetance by customers, you need to understand the customers
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="security-card">
              <i class="flaticon-web-development"></i>
              <h3><router-link to="/services/app-development">App Development</router-link></h3>
              <p>
                Either web, mobile or desktop application, we can transform any innovative idea 
                into a reliable software system.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="security-card">
              <i class="flaticon-consulting"></i>
              <h3><router-link to="/services/cloud-computing">Cloud Computing</router-link></h3>
              <p>
                We help you saving maintenance cost and preventing your IT infrastructure from 
                natural disasters.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="security-card">
              <i class="flaticon-consultant"></i>
              <h3><router-link to="/services/it-consulting">IT Consulting</router-link></h3>
              <p>
                We direct your choice and help you implementing the appropriate IT infrastructure 
                needed for your business
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="services-area-three pt-100 pb-70">
      <div class="container">
        <div class="section-title text-center">
          <span class="sp-color2">Our Services</span>
          <h2>We Provide a Wide Variety of IT Services</h2>
        </div>
        <div class="row pt-45">
          <div class="col-lg-4 col-md-6">
            <div class="services-item">
              <router-link to="/services/it-consulting">
                <img
                  src="/assets/images/services/services-img1.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <i class="flaticon-consultant"></i>
                <span><router-link to="/services/it-consulting">IT Solution</router-link></span>
                <h3><router-link to="/services/it-consulting">IT Consulting</router-link></h3>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="services-item">
              <router-link to="/services/cloud-computing">
                <img
                  src="/assets/images/services/services-img2.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <i class="flaticon-consulting"></i>
                <span><router-link to="/services/cloud-computing">Cloud Services</router-link></span>
                <h3><router-link to="/services/cloud-computing">Cloud Computing</router-link></h3>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="services-item">
              <router-link to="/services/app-development">
                <img
                  src="/assets/images/services/services-img3.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <i class="flaticon-web-development"></i>
                <span><router-link to="/services/app-development">Web Services</router-link></span>
                <h3><router-link to="/services/app-development">App Development</router-link></h3>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="services-item">
              <router-link to="/services/system-security">
                <img
                  src="/assets/images/services/services-img4.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <i class="flaticon-cyber-security"></i>
                <span><router-link to="/services/system-security">Security</router-link></span>
                <h3><router-link to="/services/system-security">System Security</router-link></h3>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="services-item">
              <router-link to="/services/blockchain">
                <img
                  src="/assets/images/services/services-img5.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <i class="flaticon-structure"></i>
                <span><router-link to="/services/blockchain">Cryptocurrency</router-link></span>
                <h3><router-link to="/services/blockchain">Blockchain</router-link></h3>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="services-item">
              <router-link to="/services/data-analysis">
                <img
                  src="/assets/images/services/services-img6.jpg"
                  alt="Images"
                />
              </router-link>
              <div class="content">
                <i class="flaticon-data-analytics"></i>
                <span><router-link to="/services/data-analysis">Analysis</router-link></span>
                <h3><router-link to="/services/data-analysis">Data Analysis</router-link></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="build-area pt-100 pb-70">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-8">
            <div class="build-content">
              <div class="section-title">
                <span>We Carry More Than Just Good Coding Skills</span>
                <h2>Let's Build Your Website!</h2>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="build-btn-area">
              <router-link
                to="/contacts"
                class="default-btn btn-bg-two border-radius-50"
                >Contact Us <i class="bx bx-chevron-right"></i
              ></router-link>
            </div>
          </div>
        </div>
        <div class="row pt-45">
          <div class="col-lg-12">
            <div class="play-btn-area">
              <a
                href="https://www.youtube.com/watch?v=tUP5S4YdEJo"
                class="build-play popup-btn"
                ><i class="bx bx-play"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="talk-area ptb-100">
      <div class="container">
        <div class="talk-content text-center">
          <div class="section-title text-center">
            <span class="sp-color1">Let's Talk</span>
            <h2>We Are Adding Kinds of IT Services That You Grow Success</h2>
          </div>
          <router-link to="/contacts" class="default-btn btn-bg-two border-radius-5"
            >Contact Us</router-link>
        </div>
      </div>
    </div>

    <section class="technology-area-two pt-100 pb-70">
      <div class="container">
        <div class="section-title text-center">
          <span class="sp-color2">Technology Index</span>
          <h2>We Deliver Our Best Solution With The Goal of Trusting</h2>
        </div>
        <div class="row pt-45">
          <div class="col-lg-2 col-6">
            <div class="technology-card technology-card-color">
              <i class="flaticon-android"></i>
              <h3>Android</h3>
            </div>
          </div>
          <div class="col-lg-2 col-6">
            <div class="technology-card technology-card-color">
              <i class="flaticon-website"></i>
              <h3>Apps</h3>
            </div>
          </div>
          <div class="col-lg-2 col-6">
            <div class="technology-card technology-card-color">
              <i class="flaticon-apple"></i>
              <h3>iOS</h3>
            </div>
          </div>
          <div class="col-lg-2 col-6">
            <div class="technology-card technology-card-color">
              <i class="flaticon-television"></i>
              <h3>TV</h3>
            </div>
          </div>
          <div class="col-lg-2 col-6">
            <div class="technology-card technology-card-color">
              <i class="flaticon-smartwatch"></i>
              <h3>Watch</h3>
            </div>
          </div>
          <div class="col-lg-2 col-6">
            <div class="technology-card technology-card-color">
              <i class="flaticon-cyber-security"></i>
              <h3>Security</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
</template>
<script>
export default {
    name: 'Home3'
}
</script>