<template>
  <div>
    <div class="inner-banner">
      <div class="container">
        <div class="inner-title text-center">
          <h3>Contact Us</h3>
          <ul>
            <li>
              <router-link to="/">Home</router-link>
            </li>
            <li>
              <i class="bx bx-chevrons-right"></i>
            </li>
            <li>Contact Us</li>
          </ul>
        </div>
      </div>
      <div class="inner-shape">
        <img src="/assets/images/shape/inner-shape.png" alt="Images" />
      </div>
    </div>

    <div class="contact-form-area pt-100 pb-70">
      <div class="container">
        <div class="section-title text-center">
          <h2>Let's Send Us a Message Below</h2>
        </div>
        <div class="row pt-45">
          <div class="col-lg-4">
            <div class="contact-info mr-20">
              <span>Contact Info</span>
              <h2>Let's Connect With Us</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                imperdiet varius mi, ut hendrerit magna mollis ac.
              </p>
              <ul>
                <li>
                  <div class="content">
                    <i class="bx bx-phone-call"></i>
                    <h3>Phone Number</h3>
                    <a href="tel:+85261384110">(+852) 61384110</a>
                  </div>
                </li>
                <li>
                  <div class="content">
                    <i class="bx bxs-map"></i>
                    <h3>Address</h3>
                    <span>Boulevard du 13 Janvier, Lome Togo</span>
                  </div>
                </li>
                <li>
                  <div class="content">
                    <i class="bx bx-mail-send"></i>
                    <h3>Contact Info</h3>
                    <span
                      class="__cf_email__"
                      data-cfemail="3159545d5d5e714554525954491f525e5c"
                      >info@tech.daragroups.com</span
                    >
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="contact-form">
              <form method="post" @submit.prevent="sendMessage">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Full Name <span>*</span></label>
                      <input
                        type="text"
                        v-model="userContact.fullName"
                        class="form-control"
                        placeholder="Your Full Name"
                      />
                      <div class="help-block with-errors">
                        {{ errors.fullName }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Email <span>*</span></label>
                      <input
                        type="text"
                        v-model="userContact.email"
                        class="form-control"
                        placeholder="Your Email"
                      />
                      <div class="help-block with-errors">
                        {{ errors.email }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Phone Number <span>*</span></label>
                      <input
                        type="text"
                        v-model="userContact.phoneNumber"
                        class="form-control"
                        placeholder="Your Phone Number"
                      />
                      <div class="help-block with-errors">
                        {{ errors.phoneNumber }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Subject <span>*</span></label>
                      <input
                        type="text"
                        v-model="userContact.subject"
                        class="form-control"
                        placeholder="Your Subject"
                      />
                      <div class="help-block with-errors">
                        {{ errors.subject }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Your Message <span>*</span></label>
                      <textarea
                        v-model="userContact.message"
                        class="form-control"
                        cols="30"
                        rows="8"
                        placeholder="Write Your Message"
                      ></textarea>
                      <div class="help-block with-errors">
                        {{ errors.message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 text-center">
                    <button
                      type="submit"
                      class="default-btn btn-bg-two border-radius-50"
                    >
                      Send Message <i class="bx bx-chevron-right"></i>
                    </button>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import validator from "validator";

export default {
  name: "Contacts",
  data() {
    return {
      userContact: {
        fullName: "",
        email: "",
        phoneNumber: "",
        subject: "",
        message: "",
      },
      errors: {},
      messageRes: null,
    };
  },
  methods: {
    sendMessage() {
      this.errors = {};
      if (validator.isEmpty(this.userContact.fullName))
        this.errors.fullName = "Full name is required!";
      if (validator.isEmpty(this.userContact.email))
        this.errors.email = "Email is required!";
      if (!validator.isEmail(this.userContact.email))
        this.errors.email = "Email must be valid!";
      if (validator.isEmpty(this.userContact.phoneNumber))
        this.errors.phoneNumber = "Phone number is required!";
      if (validator.isEmpty(this.userContact.subject))
        this.errors.subject = "Subject is required!";
      if (validator.isEmpty(this.userContact.message))
        this.errors.message = "Message is required!";
      if (!Object.keys(this.errors).length) {
        let self = this;
        axios
          .post(`${process.env.VUE_APP_API}/api/send/mail`, this.userContact)
          .then((res) => {
            self.messageRes = res.data.message;
            self.showMessage();
            self.resetForm();
          })
          .catch((err) => console.log(err));
      }
    },
    resetForm() {
      this.userContact = {};
    },
    showMessage() {
      this.successMessage(this.messageRes);
    },
  },
};
</script>