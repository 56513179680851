import Vue from 'vue'
import VueRouter from 'vue-router'

// home
import Home from '@/views/Home.vue'
// import Home1 from '@/views/home/Home1.vue'
import Home2 from '@/views/home/Home2.vue'
import Home3 from '@/views/home/Home3.vue'
import Home4 from '@/views/home/Home4.vue'
import Home5 from '@/views/home/Home5.vue'

import About from '@/views/About.vue'

import Digitalization from '@/views/Digitalization.vue'
import IT from '@/views/IT.vue'
import Softwares from '@/views/Softwares.vue'
import Innovation from '@/views/Innovation.vue'

// pages
import Team from '@/views/pages/Team.vue'
import Testimonials from '@/views/pages/Testimonials.vue'
import Faq from '@/views/pages/Faq.vue'
import ComingSoon from '@/views/pages/ComingSoon.vue'
import Page404 from '@/views/pages/Page404.vue'
import PrivacyPolicy from '@/views/pages/PrivacyPolicy.vue'
import TermsAndConditions from '@/views/pages/TermsAndConditions.vue'
// pages-case study
import CaseStudy from '@/views/pages/case_study/CaseStudy.vue'
import CaseStudyDetails from '@/views/pages/case_study/CaseStudyDetails.vue'
// pages-user
import Login from '@/views/pages/user/Login.vue'
import Registration from '@/views/pages/user/Registration.vue'
import ForgetPassword from '@/views/pages/user/ForgetPassword.vue'

// services
// import Service1 from '@/views/services/Service1.vue'
import Service2 from '@/views/services/Service2.vue'
import PaymentSystemsDetails from '@/views/services/PaymentSystemsDetails.vue'
import BlockchainDetails from '@/views/services/BlockchainDetails.vue'
import AppDevelopmentDetails from '@/views/services/AppDevelopmentDetails.vue'
import DataAnalysisDetails from '@/views/services/DataAnalysisDetails.vue'
import CloudComputingDetails from '@/views/services/CloudComputingDetails.vue'
import MachineLearningDetails from '@/views/services/MachineLearningDetails.vue'
import SystemSecurityDetails from '@/views/services/SystemSecurityDetails.vue'
import ITConsultingDetails from '@/views/services/ITConsultingDetails.vue'

// blog
import Blog1 from '@/views/blog/Blog1.vue'
import Blog2 from '@/views/blog/Blog2.vue'
import BlogDetails from '@/views/blog/BlogDetails.vue'

import Contacts from '@/views/Contacts.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/home/home-2',
    name: 'Home2',
    component: Home2,
  },
  {
    path: '/home/home-3',
    name: 'Home3',
    component: Home3,
  },
  {
    path: '/home/home-4',
    name: 'Home4',
    component: Home4,
  },
  {
    path: '/home/home-5',
    name: 'Home5',
    component: Home5,
  },
  {
    path: '/about-us',
    name: 'About',
    component: About
  },
  {
    path: '/digitalization',
    name: 'Digitalization',
    component: Digitalization
  },
  {
    path: '/it',
    name: 'IT',
    component: IT
  },
  {
    path: '/softwares',
    name: 'Softwares',
    component: Softwares
  },
  {
    path: '/innovation',
    name: 'Innovation',
    component: Innovation
  },
  {
    path: '/pages/team',
    name: 'Team',
    component: Team
  },
  {
    path: '/pages/testimonials',
    name: 'Testimonials',
    component: Testimonials
  },
  {
    path: '/pages/faq',
    name: 'Faq',
    component: Faq
  },
  {
    path: '/pages/case-study/case-study',
    name: 'CaseStudy',
    component: CaseStudy
  },
  {
    path: '/pages/case-study/case-study-details',
    name: 'CaseStudyDetails',
    component: CaseStudyDetails
  },
  {
    path: '/pages/user/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/pages/user/registration',
    name: 'Registration',
    component: Registration
  },
  {
    path: '/pages/user/forget-password',
    name: 'ForgetPassword',
    component: ForgetPassword
  },
  {
    path: '/pages/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions
  },
  {
    path: '/pages/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/pages/page-404',
    name: 'Page404',
    component: Page404
  },
  {
    path: '/pages/coming-soon',
    name: 'ComingSoon',
    component: ComingSoon
  },
  {
    path: '/blog/blog-1',
    name: 'Blog1',
    component: Blog1
  },
  {
    path: '/blog/blog-2',
    name: 'Blog2',
    component: Blog2
  },
  {
    path: '/blog/blog-details',
    name: 'BlogDetails',
    component: BlogDetails
  },
  // {
  //   path: '/services/service-1',
  //   name: 'Service1',
  //   component: Service1
  // },
  {
    path: '/services',
    component: Service2
  },
  {
    path: '/services/payment-systems',
    name: 'PaymentSystemsDetails',
    component: PaymentSystemsDetails
  },
  {
    path: '/services/blockchain',
    name: 'BlockchainDetails',
    component: BlockchainDetails
  },
  {
    path: '/services/cloud-computing',
    name: 'CloudComputingDetails',
    component: CloudComputingDetails
  },
  {
    path: '/services/data-analysis',
    name: 'DataAnalysisDetails',
    component: DataAnalysisDetails
  },
  {
    path: '/services/app-development',
    name: 'AppDevelopmentDetails',
    component: AppDevelopmentDetails
  },
  {
    path: '/services/it-consulting',
    name: 'ITConsultingDetails',
    component: ITConsultingDetails
  },
  {
    path: '/services/machine-learning',
    name: 'MachineLearningDetails',
    component: MachineLearningDetails
  },
  {
    path: '/services/system-security',
    name: 'SystemSecurityDetails',
    component: SystemSecurityDetails
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
