<template>
    <div>
      <div class="coming-soon-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <div class="coming-soon-content">
              <h1>Coming Soon</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices.
              </p>
              <div id="timer">
                <div id="days"></div>
                <div id="hours"></div>
                <div id="minutes"></div>
                <div id="seconds"></div>
              </div>
              <form class="newsletter-form" data-toggle="validator">
                <input
                  type="email"
                  class="input-newsletter"
                  placeholder="Enter email address"
                  name="EMAIL"
                  required
                  autocomplete="off"
                />
                <button type="submit" class="default-btn btn-bg-two">
                  Notify Me
                </button>
                <div id="validator-newsletter" class="form-result"></div>
              </form>
              <ul class="header-content-right">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="bx bxl-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/?lang=en" target="_blank">
                    <i class="bx bxl-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="bx bxl-linkedin-square"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/" target="_blank">
                    <i class="bx bxl-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
export default {
    name: 'ComingSoon'
}
</script>